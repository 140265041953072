import axios from "axios";
import React, {Component} from "react";
import LessonForm from "./LessonForm";
import FeatherIcon from 'feather-icons-react';

class   Lesson extends Component{

    state = {
        isModal : false,
        formData : null,
        lessons : [],
    }

    componentDidMount(){
        this.getLessons();
    }

    async getLessons(){
        var lessons = await axios.get('lessons');
        if(lessons.status === 200)
        {
            this.setState({lessons : lessons.data}) 
        }
    }

    showModal = (lesson = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : lesson,
        })
        this.getLessons()
    }

    delete = (lesson) =>{
        axios.delete("lessons/" + lesson).then((response) => {
            this.getLessons()
        }).catch((error)=>{})
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        var lessons =
        this.state.lessons.map((lesson) => {
            return(
                <li className="widget-todo-item list-group-item m-2 border rounded-3" key={lesson.id}>
                    <div
                        className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                        <div className="widget-todo-title-area d-flex align-items-center">
                            <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                            <label htmlFor={lesson.id}><span className="widget-todo-title ml-50">{lesson.entitled_lesson}  </span></label>
                        </div>
                        {
                            this.handleCheck("Modifier cours") == true && (
                                <div className="widget-todo-item-action d-flex align-items-center">
                                    <FeatherIcon icon="edit-2" size="16" className='cursor-move text-primary' type="button" onClick={() => this.showModal(lesson)}></FeatherIcon>
                                </div>
                            )
                        }
                    </div>
                </li>
            )
        })
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Cours</h5>
                {
                    this.handleCheck("Ajouter cours") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className=" mb-4 overflow-auto">
                <div className="">
                    <ul className="widget-todo-list-wrapper p-0">
                        { lessons }
                    </ul>
                </div>
            </div>
            
            <LessonForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Lesson;