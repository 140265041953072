import axios from "axios";
import React, {Component} from "react";
import ReportcardForm from "./ReportcardForm";
import DataRecordcard from "./DataRecordcard";
import Loading from "../../Loading";

class   Reportcard extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : null,
        sections : [],
    }

    componentDidMount(){
        this.getSections();
    }

    async getSections(){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({sections : sections.data, loading : true}) 
        }
    }

    showModal = (section = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : section,
        })
        this.getSections()
    }

    delete = (section) =>{
        axios.delete("reportcards/" + section).then((response) => {
            this.getSections()
        }).catch((error)=>{})
    }

    render(){

        var sections =
        this.state.sections.map((section) => {
           return(
                <DataRecordcard section={section} key={section.id} />
            )
        })
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Bulletins des élèves</h5>
                {/* <div className="btn-toolbar mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                    </div>
                </div> */}
            </div>

            <div className="row">
                <div className="col-md-12 ">
                    <ul className="widget-todo-list-wrapper p-0">
                        { this.state.loading ?  sections : <Loading /> }
                    </ul>
                </div>
                
            </div>
            
            <ReportcardForm
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Reportcard;