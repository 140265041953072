import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   DisciplineForm extends Component{

    state = {
        id : "",
        motif : "",
        description : "",
        studentId : "",
        students : [],
        periodes : [],
        error : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeDescription = () =>{
        var data = {
            studentId : this.state.studentId ,
            periodeId : this.state.periodeId ,
            motif : this.state.motif,
            description : this.state.description,
        }
        
        var request = this.state.id ? axios.put('disciplines/'+this.state.id, data) : axios.post('disciplines', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async getStudents(){
        var data=[], students = await axios.get('students');
        if(students.status === 200)
        {
            students.data.map((student) => {
                data.push({value : student.id, label : student.matricule + " " + student.user.last_name + " " + student.user.first_name})
            })
            
            this.setState({
                students : data
            })
        }
    }

    async getPeriodes(){
        var data=[], periodes = await axios.get('periodes');
        if(periodes.status === 200)
        {
            periodes.data.map((periode) => {
                data.push({value : periode.id, label : periode.periode })
            })
            
            this.setState({
                periodes : data
            })
        }
    }

    edit=()=>  {
        this.form()
        this.getStudents();
        this.getPeriodes();
    }

    form=()=>{
        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            motif : this.props.formData ? this.props.formData.motif : "",
            description : this.props.formData ? this.props.formData.description : "",
            studentId : this.props.formData  ? this.props.formData.student.id : "",
            title : this.props.formData ? "Modifier les infomations du discipline" : "Ajout du discipline",
            error : "",
        })
    }

    render(){

        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row" style={{  fontSize :  14 + "px" }}>
                        <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                        
                        <div className=" col-md-12 mt-2">
                            <label className="font-monospace fw-normal">Eleve</label>
                            <div className="input-group">
                                <Select
                                    className="form-control fomr-control-sm p-0"
                                    value = {
                                        this.state.students.filter(option => 
                                        option.value == this.state.studentId)
                                    }
                                    defaultValue={this.state.studentId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                studentId : select.value
                                            });
                                        }
                                    }
                                    options={this.state.students}
                                />
                            </div>
                        </div>

                        <div className="col-md-8 mt-2">
                            <div className="form-group">
                                <label htmlFor="email-id-icon">Motif</label>
                                <div className="position-relative mt-1">
                                    <textarea className="form-control form-control-sm" rows={2} onChange={this.handleInput} name="motif"  value={this.state.motif}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Période</label>
                            <div className="input-group">
                                <Select
                                    className="form-control fomr-control-sm p-0"
                                    value = {
                                        this.state.periodes.filter(option => 
                                        option.value == this.state.periodeId)
                                    }
                                    defaultValue={this.state.periodeId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                periodeId : select.value
                                            });
                                        }
                                    }
                                    options={this.state.periodes}
                                />
                            </div>
                        </div>

                        <div className="col-md-12 ">
                            <div className="col-md-12 mt-2">
                                <div className="form-group">
                                    <label htmlFor="numero-id-icon">Description</label>
                                    <div className="position-relative mt-1">
                                         <textarea className="form-control form-control-sm" rows={3}  onChange={this.handleInput} name="description"  value={this.state.description}></textarea>
                                    </div>
                                </div>
                            </div>
 
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeDescription()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
        </div>
        )
    }
}
export default DisciplineForm;