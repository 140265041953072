import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   PresenceForm extends Component{

    state = {
        id : null,
        studentId : null,
        staffId : null,
        date : "",
        time_start : "",
        time_end : "",
        error : "",
        
        staffs : [],
        isModal : false,
        title : null,
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storePresence = () =>{
        var data = {
                studentId : this.state.studentId,
                staffId : this.state.staffId,
                date : this.state.date,
                time_start : this.state.time_start,
                time_end : this.state.time_end,
            }
            
        var request = this.state.id ? axios.put('presences/'+this.state.id, data) : axios.post('presences', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            console.log(error)
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    
    componentDidMount() {
        this.form();
        this.getStaffs()
    }

    form=()=>{
        var date_start =  new Date();
        var mois = date_start.getMonth()+1;
            mois = mois >= 10 ? mois : "0"+mois;
        var jour = date_start.getDate() >= 10 ? date_start.getDate() : "0"+date_start.getDate();
        date_start = date_start.getFullYear()  + "-" + (mois) + "-" + jour;

        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            // studentId: this.props.formData ? this.props.formData.studentId : this.state.studentId,
            // staffId: this.props.formData ? this.props.formData.staffId : this.state.staffId,
            date: date_start,
            time_start: this.props.formData ? this.props.formData.time_start : "",
            time_end: this.props.formData ? this.props.formData.time_end : "",
            title : this.props.formData ? "Modifier le type de cours" : "Ajouter le type de cours",
            error : "",
        })
    }


    async getStaffs(){
        var data=[], staffs = await axios.get('staffs');
        
        if(staffs.status === 200)
        {
            staffs.data.map((staff) => {
                data.push({value : staff.id, label : staff.matricule + " " + staff.user.last_name + " " + staff.user.first_name })
            })
            
            this.setState({
                staffs : data
            })
        }
    }
    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        
        return(
            <div>
                <div className="row">

                    <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                    <div className="col-md-4 mt-2 mt-2">
                        <div className="form-group has-icon">
                            <label htmlFor="nom-id-icon">Personnel</label>
                            <div className="position-relative mt-1">
                                <div className="input-group">
                                    <Select className="form-control form-control-sm p-0"
                                        value = {
                                            this.state.staffs.filter(option => option.value == this.state.staffId)
                                        }
                                        defaultValue={this.state.staffId}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    staffId : select.value
                                                }); 
                                                this.form()
                                            }
                                        }
                                        options={this.state.staffs.sort((a, b) => (a.label > b.label ? 1 : -1))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-2 mt-2">
                        <div className="form-group">
                            <label htmlFor="nom-id-icon">Date actuelle</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="date"  value={this.state.date} id="nom-id-icon" />
                           </div>
                        </div>
                    </div>

                    <div className="col-md-3 mt-2 mt-2">
                        <div className="form-group">
                            <label htmlFor="nom-id-icon">Heure d'arrivée</label>
                            <div className="position-relative mt-1">
                                <input type="time" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="time_start"  value={this.state.time_start} id="nom-id-icon" />
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="col-md-2 mt-4">
                        <button onClick={() => this.storePresence()} className="mt-2 btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon></button>
                    </div>
                </div>
            </div>
        )
    }
}
export default PresenceForm;