import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Sectionclasselesson from "./pages/st/sectionclasselessons/Sectionclasselesson";
import Schedule from "./pages/st/schedules/Schedule";
import Evaluation from "./pages/st/evaluations/Evaluation";
import Examen from "./pages/st/examens/Evaluation";
import Draft from "./pages/st/drafts/Evaluation";
import Reportcard from "./pages/st/reportcards/Reportcard";
import Studentreportcard from "./pages/st/reportcards/Studentreportcard";
import Question from "./pages/st/questions/Question";
import Note from "./pages/st/notes/Note";
import Login from "./partials/Login";
import Report from "./pages/st/reports/Report";
import Archive from "./pages/st/archives/Archive";
import Notification from "./pages/users/Notification";
import Profile from "./pages/users/Profile";
import Search from "./pages/users/Search";
import ArchiveDetail from "./pages/st/archives/ArchiveDetail";
import Reportcardfile from "./pages/st/reportcards/Reportcardfile";
import Reportcardfail from "./pages/st/reportcards/Reportcardfail";
import Discipline from "./pages/st/disciplines/Discipline";
import Presence from "./pages/st/presences/Presence";
import Sms from "./pages/st/sms/Sms";
 

function handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }
function RouterPage(){

    return(
        <Switch>
            {
                handleCheck("Afficher tableau de bord") == true && (
                    <Route exact path="/" component={Dashboard} />
                )
            }
            {
                handleCheck("Afficher cours") == true && (
                    <Route  path="/sectionclasselessons" component={Sectionclasselesson} />
                )
            }
            {
                handleCheck("Afficher horaire") == true && (
                    <Route  path="/schedules" component={Schedule} />
                )
            }
            {
                handleCheck("Afficher bulletin") == true && (
                    <Route  path="/students/:student" component={Studentreportcard} />
                )
            }
            {
                handleCheck("Afficher bulletin") == true && (
                    <Route  path="/reportcards" component={Reportcard} />
                )
            }

            {
                handleCheck("Afficher bulletin") == true && (
                    <Route  path="/presences" component={Presence} />
                )
            }

            {
                handleCheck("Ajouter élève") == true && (
                    <Route  path="/sms" component={Sms} />
                )
            }

            {
                handleCheck("Afficher bulletin") == true && (
                    <Route  path="/reportcardfails" component={Reportcardfail} />
                )
            }

            {
                handleCheck("Afficher evaluation") == true && (
                    <Route  path="/reportcardfiles" component={Reportcardfile} />
                )
            }

            {
                handleCheck("Afficher note") == true && (
                    <Route  path="/evaluations/:evaluation/notes" component={Note} />
                )
            }

            {
                handleCheck("Afficher discipline") == true && (
                    <Route  path="/disciplines" component={Discipline} />
                )
            }

            {
                handleCheck("Afficher note") == true && (
                    <Route  path="/evaluations/:evaluation/questions" component={Question} />
                )
            }
            {
                handleCheck("Afficher evaluation") == true && (
                    <Route  path="/evaluations" component={Evaluation} />
                )
            }
            {
                handleCheck("Afficher rapport") == true && (
                    <Route  path="/repports" component={Report} />
                )
            }
            {
                handleCheck("Afficher archive") == true && (
                    <Route  path="/archives/student/:year" component={ ArchiveDetail } />
                )
            }
            {
                handleCheck("Afficher archive") == true && (
                    <Route  path="/archives" component={Archive} />
                )
            }
            {
                handleCheck("Afficher evaluation") == true && (
                    <Route  path="/examens" component={Examen} />
                )
            }
            {
                handleCheck("Afficher evaluation") == true && (
                    <Route  path="/drafts" component={Draft} />
                )
            }
            
            <Route  path="/search/:search" component={ Search } />
            <Route  path="/get/:user_id" component={ Login } />
.            <Route  path="/login" component={ Login } />
            <Route  path="/profile" component={Profile} />
            <Route  path="/notifications" component={Notification} />
            <Route component={NotFound} />
        </Switch>
    )
}

export default RouterPage;