import React, { Component } from 'react';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import ReactLoading from "react-loading";
import { toast } from 'react-toastify';

class Login extends Component {
    state = {
        username: "",
        password: "",
        error : "",
        click : false
    }
     componentDidMount(){
        window.localStorage.clear();
    }
    

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    login  = () => {
        this.setState({
            click : true,
            error : ""
        })
         axios.post('login', {
            username: this.state.username,
            password: this.state.password
        }).then((login) => {
            localStorage.setItem("user", JSON.stringify(login.data.data));
            localStorage.setItem("profiles", JSON.stringify(login.data.profiles));

            if(login.data.profiles.length === 1)
            {
                localStorage.setItem("profile", JSON.stringify(login.data.profiles[0]));
            }
            localStorage.setItem("permissions", login.data.permissions ? JSON.stringify(login.data.permissions) : []);
            window.location.replace(process.env.REACT_APP_EVIVESOMA_STAT)
        }).catch((error) => {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();

            this.setState({
                error: error.response.data.msg,
            });
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    render() {

        return (
            <div id="auth">
                
                        <section className="section register min-vh-50 d-flex flex-column align-items-center justify-content-center ">
                            <div className="container">
                            <div className="row justify-content-center mt-4  ">
                                <div className="col-md-5 col-sm-12 mx-auto d-flex flex-column align-items-center justify-content-center p-0">
                    

                                <div className="card mt-4 shadow overflow-hidden rounded-3 mx-4">

                                    <div className="card-body">

                                    <div className="mt-1">
                                        <img className="d-block mx-auto" src="/logo192.png" alt="" width="100" height="80" />
                                        <h5 className="font-monospace text-center  fs-4">{ process.env.REACT_APP_NAME } Stat</h5>
                                        {/* <div className="text-center small lead">Entrer votre Email et mot de passe pour vous authentifier</div> */}
                                    </div>

                                    <form className="row g-3 needs-validation">
                                        {this.state.error && (<div className="border  border-1 rounded-3 border-danger text-center text-danger py-1 px-2 text-small">{this.state.error}</div>)}
                                        <div className="col-12">
                                            <label htmlFor="yourUsername" className="form-label font-monospace">Email ou téléphone</label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text bg-white" id="inputGroupPrepend"><FeatherIcon icon={"lock"} size={13}></FeatherIcon> </span>
                                                <input type="text"  name="username" onChange={this.handleInput} className="form-control form-control-sm bg-white " id="yourUsername" />
                                            </div>
                                        </div>

                                        <div className="col-12">
                                            <label htmlFor="yourPassword" className="form-label font-monospace">Mot de passe</label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text bg-white" id="inputGroupPrepend"><FeatherIcon icon={"key"} size={13}></FeatherIcon> </span>
                                                <input type="password" name="password" className="form-control form-control-sm bg-white " onChange={this.handleInput} id="yourPassword" />
                                            </div>
                                        </div>
                                        
                                        <div className="col-12">
                                            <button className="btn btn-primary btn-sm w-100" type='button' onClick={() => this.login()}>
                                            <div className='d-flex justify-content-center'>
                                                {
                                                    this.state.click && (
                                                        !this.state.error && (
                                                            <ReactLoading type="spokes" color="white" height={20} width={15} />
                                                        )
                                                    )
                                                }
                                                <span>Connexion</span>
                                            </div>    
                                            </button>
                                        </div>
                                        <div className="col-12">
                                        <p className="small mb-0">Vous n'avez pas un compte? <a href="#http://evivesomarh.app"  className="text-decoration-none">Contactez le responsable</a></p>
                                        </div>
                                    </form>

                                    </div>
                                </div>

                                </div>
                            </div>
                            </div>

                        </section>
                 
            </div>
        );
    }
}


export default Login;