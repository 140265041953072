import axios from "axios";
import React, {Component} from "react";
import ScheduleForm from "./ScheduleForm";
import Select from 'react-select';
import Loading from "../../Loading";
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from "react-to-print";
import Headerpdf from "../../users/Headerpdf";

class   Schedule extends Component{

    state = {
        isModal : false,
        formData : null,
        periode : "",
        sectionclasse : "",
        schedules : [],
        periodes : [],
        sections : [],
        days : [],
        laoding : false,
    }

    componentDidMount(){
        this.getPeriodes();
        this.getSections();
        this.getDays();
        this.getSchedules();
    }

    async getSchedules(){
        var schedules = await axios.get('schedules');
        if(schedules.status === 200)
        {
            this.setState({schedules : schedules.data, laoding : true}) 
        }
    }

    async getSections(){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({sections : sections.data}) 
        }
    }

    async getDays(){
        var days = await axios.get('days');
        if(days.status === 200)
        {
            this.setState({days : days.data}) 
        }
    }

    async getPeriodes(){
        var periodes = await axios.get('periodes');
        if(periodes.status === 200)
        {
            this.setState({periodes : periodes.data, laoding : true}) 
        }
    }

    showModal = (schedule = null, sectionclasse = null) => {
        this.setState({
            sectionclasse : sectionclasse,
            isModal : !this.state.isModal,
            formData :  schedule,
        })
        this.getSchedules()
    }

    delete = (schedule) =>{
        axios.delete("schedules/" + schedule).then((response) => {
            this.getSchedules()
        }).catch((error)=>{})
    }
  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        var schedules =
        this.state.sections.sort((a, b) => (a.short_entitled_section > b.short_entitled_section ? 1 : -1)).map((section) =>{
            
            return(
                section.sectionclasses.sort((a, b) => ((a.class.short_entitled_class > b.class.short_entitled_class && a.title > b.title) ? 1 : -1)).map((sectionclass) =>{
                    
                    return(
                        <li className="widget-todo-item list-group-item m-2   border rounded-3 shadow">
                            <div
                                className="widget-todo-title-wrapper d-flex justify-content-between align-items-center  ">
                                <div className="widget-todo-title-area d-flex align-items-center">
                                {
                                    this.handleCheck("Ajouter horaire") == true && (
                                        <div className="btn-toolbar m-0 mb-md-0 table_action ">
                                            <div className="btn-group">
                                                <button type="button" className="btn btn-sm text-primary" onClick={() => this.showModal(null, sectionclass)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                                            </div>
                                        </div>
                                    )
                                }<FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                                    <label htmlFor={sectionclass.id}><span className="widget-todo-title ml-50">{sectionclass.class.short_entitled_class}  <span className="text-primary">{sectionclass.title}</span> { section.short_entitled_section} </span></label>
                                </div>
                                <div className="widget-todo-item-action d-flex align-items-center">
                                    
                                    {section.short_entitled_section} <FeatherIcon icon="list" size="16" className='cursor-move text-dark'></FeatherIcon>
                                </div>
                            </div>
                            <div className="table-responsive m-2">

                                <div className="d-none">
                                    <div className="text-center h6">
                                        <label htmlFor={sectionclass.id}><span className="widget-todo-title ml-50">Horaire {sectionclass.class.short_entitled_class} {sectionclass.class.entitled_class} <span className="text-primary">{sectionclass.title}</span> { section.short_entitled_section} </span></label>
                                    </div>
                                </div>
                                <table className="table table-striped table-sm">
                                    <thead>
                                        <tr>
                                        {
                                            this.state.days.map((day) => {
                                                return(<th className="border" scope="col" key={day.id +"evivesoma"}> {day.day}</th>)
                                                })
                                        }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.days.map((day) => {
                                                return (
                                                        <td className="border" key={day.id}>
                                                            {
                                                                this.state.schedules.filter(schedule => this.state.periode && schedule.periode.id === this.state.periode  && schedule.sectionclasselesson.sectionclass.id == sectionclass.id && schedule.dayId == day.id || !this.state.periode && schedule.sectionclasselesson.sectionclass.id == sectionclass.id && schedule.dayId == day.id).map((schedule) => {
                                                                   return(
                                                                        <tr className="" key={schedule.id}>
                                                                            <td type="button" title={"modifier " + schedule.date_start + " au " + schedule.date_end } onClick={ () => this.showModal(schedule, sectionclass)}>
                                                                                { schedule.sectionclasselesson.lesson.short_entitled_lesson} <small className="fw-lighter text-primary small" style={{ fontSize : 11 +"px"}}>{schedule.periode.id != 3 && schedule.periode.id != 6 && schedule.periode.id != 7 ? "("+schedule.periode.id + "P)" : "" }{schedule.periode.id == 3 ? "(Exa1)" : "" }{schedule.periode.id == 6 ? "(Exa2)" : "" }{schedule.periode.id == 7 ? "(2°S)" : "" }</small> <img src={ schedule.staff.user.avatar} title={schedule.staff.user.matricule + " "+schedule.staff.user.first_name} className="rounded-circle" height={"30px"} /> 
                                                                                <span className="fw-lighter" style={{ fontSize : 11 +"px"}}> { schedule.time_start ? schedule.time_start.slice(0, 5) : "" } { schedule.time_end ? schedule.time_end.slice(0, 5) : "" }</span>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </td>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                
                            </div>
                        </li>
                    )
                })
            )
        })


        var optionPeriodes = [ ];
        this.state.periodes.map(periode => {
            optionPeriodes.push({ value : periode.id, label : periode.periode })
        })
        return(
            <div>
            {/* {
                this.handleCheck("Ajouter horaire") == true && (
                    <div className="btn-toolbar m-0 mb-md-0 float-end text-end">
                        <div className="btn-group">
                            <button type="button" className="btn btn-sm text-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                        </div>
                    </div>
                )
            } */}
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Horaire</h5>
                <div className="">
                    <Select className="form-control form-control-sm"
                        value = {
                            optionPeriodes.filter(option => 
                            option.value === this.state.periode)
                        }
                        defaultValue={this.state.periode}
                        onChange={
                            (select) => {
                                this.setState({
                                    periode : select.value
                                });
                            }
                        }
                        options={optionPeriodes}
                    />
                </div>
                
            </div>

            <div className="row">
                
                <div className="col-md-12">
                    <div className="text-end mb-2 mb-md-0">
                        <div className="btn-group me-2">
                            <ReactToPrint
                                trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                                content={() =>  this.componentRef}
                            />
                        </div>
                    </div>
                    <ul className="widget-todo-list-wrapper p-0"  ref={el => (this.componentRef = el)}>
                        <Headerpdf />
                            { this.state.laoding ?  schedules : <Loading /> }
                    </ul>
                </div>
            </div>
            
            <ScheduleForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                sectionclasse = {this.state.sectionclasse}
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Schedule;