
import axios from "axios";
import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./partials/Footer";
import Login from "./partials/Login";
import Navbar from './partials/Navbar';
import Sidebar from './partials/Sidebar';
import RouterPage from "./RouterPage";
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from "react-toastify";

function App() {
  const token = localStorage.getItem("user")
  var padding = "p-3";

  const queryParams = new URLSearchParams(window.location.search);
  const redirect = queryParams.get('redirect');
  if(redirect)
  {
      axios.post('fastlogin', { id : redirect}).then((login) => {
        localStorage.setItem("user", JSON.stringify(login.data.data));
        localStorage.setItem("profiles", JSON.stringify(login.data.profiles));

        if(login.data.profiles.length === 1)
        {
            localStorage.setItem("profile", JSON.stringify(login.data.profiles[0]));
        }
        localStorage.setItem("permissions", login.data.permissions ? JSON.stringify(login.data.permissions) : []);
        window.location.replace(process.env.REACT_APP_EVIVESOMA_STAT)
    }).catch((error) => {
        window.location.replace(process.env.REACT_APP_EVIVESOMA_STAT)
    })
  }

  if(!redirect && !token && window.location.href != process.env.REACT_APP_EVIVESOMA_STAT + "/login")
    return <Login />
  else if(window.location.href == process.env.REACT_APP_EVIVESOMA_STAT + "/login")
    padding = "";
 
  return (
    <Router>
      <div className={ redirect ? "container-fluid d-none" : "container-fluid"}>
            <div className="row flex-nowrap">
              { ( window.location.pathname.slice(0,10) != "/students/" && token) && (<Sidebar /> ) }
              <div className="col p-0 m-0">
              { ( window.location.pathname.slice(0,10) != "/students/" && token) && (<Navbar />)}
                  
                  <div className={"row col-md-12 " +padding} >
                    <div className=" mb-5 pb-5">
                      <RouterPage />
                    </div>
                    <ToastContainer />
                    { token && (<Footer />)}
                  </div>
              </div>
            </div>
      </div>
    </Router>
  );
}

export default App;
