import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   SectionclasselessonForm extends Component{

    state = {
        id : "",
        max_exam : "",
        max_evaluation : "",
        indice : 0,
        sectionclasse_id : "",
        lesson_id : "",
        staff_id : "",
        domaine_id : "",

        error : "",
        
        staffs : [],
        lessons : [],
        domaines : [],
        sectionclasses : [],
        isModal : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeLesson = () =>{
        var data = {
                sectionclasseId : this.state.sectionclasse_id,
                sectionclassId : this.state.sectionclasse_id,
                lessonId : this.state.lesson_id ,
                staffId : this.state.staff_id ,
                max_examen : this.state.max_exam > 0 ? this.state.max_exam : null,
                max_evaluation : this.state.max_evaluation ? this.state.max_evaluation : null,
                indice : this.state.indice ? this.state.indice : null,
                domaineId : this.state.domaine_id,
                operation : this.state.id ? "Editer" : "Ajouter",
            }

        var request = this.state.id ? axios.put('sectionclasselessons/'+this.state.id, data) : axios.post('sectionclasselessons', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async sectionclasses(){
        var request = await axios.get('sectionclasses');
        if(request.status === 200)
            this.setState({sectionclasses : request.data})
    }

    async lessons(){
        var request = await axios.get('lessons');
        if(request.status === 200)
            this.setState({lessons : request.data})
    }

    async domaines(){
        var request = await axios.get('domaines');
        if(request.status === 200)
            this.setState({domaines : request.data})
    }

    async staffs(){
        var request = await axios.get('staffs');
        if(request.status === 200)
            this.setState({staffs : request.data})
    }
    
    edit=()=>  {
        this.form()
        this.lessons()
        this.domaines()
        this.staffs()
        this.sectionclasses()
    }

    form=()=>{
        let indice = this.state.indice >= 0 ? parseInt(this.state.indice) + 1 : 1;
        
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            max_exam: this.props.formData ? this.props.formData.max_examen : this.state.max_exam,
            max_evaluation: this.props.formData ? this.props.formData.max_evaluation : this.state.max_evaluation,
            indice: this.props.formData ? this.props.formData.indice > 0 ? this.props.formData.indice : indice : indice,
            sectionclasse_id: this.props.formData ? this.props.formData.sectionclasseId: this.props.sectionclasse,
            lesson_id: this.props.formData ? this.props.formData.lesson ? this.props.formData.lesson.id : "" : "",
            domaine_id: this.props.formData ? this.props.formData.domaineId: "",
            staff_id: this.props.formData ? this.props.formData.staff ? this.props.formData.staff.id : "" : "",
            title : this.props.formData ? "Modifier le cours" : "Ajouter le cours",
            error : "",
        })
    }

    delete = () =>{
        axios.delete("sectionclasselessons/" + this.state.id).then((response) => {
            this.props.showModal()
        }).catch((error)=>{
            this.setState({
                error : error.response.data.msg
            })
        })
    }


    render(){
        var sectionclasses = [], optioncours = [], optionstaffs = [], optiondomaines =[];
        this.state.sectionclasses.map((sectionclasse) => {
            sectionclasses.push({ value : sectionclasse.id, label : sectionclasse.class.short_entitled_class + " "+ sectionclasse.title + " "+sectionclasse.section.short_entitled_section })
        })
        
        this.state.lessons.map((lesson) => {
            optioncours.push({ value : lesson.id, label : lesson.entitled_lesson})
        })

        this.state.domaines.map((domaine) => {
            optiondomaines.push({ value : domaine.id, label : domaine.title})
        })

        this.state.staffs.map((staff) => {
            optionstaffs.push({ value : staff.id, label : staff.matricule + " "+ staff.user.first_name +" "+ staff.user.last_name})
        })

        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">

                    <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                    <div className="form-group col-md-6 mt-2">
                        <label className="font-weight-bolder">Classe</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    sectionclasses.filter(option => 
                                    option.value == this.state.sectionclasse_id)
                                }
                                defaultValue={this.state.sectionclasse_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            sectionclasse_id : select.value
                                        });
                                    }
                                }
                                options={sectionclasses}
                            />
                        </div>
                    </div>

                    <div className="form-group col-md-6 mt-2">
                        <label className="font-weight-bolder">Domaine</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    optiondomaines.filter(option => 
                                    option.value == this.state.domaine_id)
                                }
                                defaultValue={this.state.domaine_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            domaine_id : select.value
                                        });
                                    }
                                }
                                options={optiondomaines}
                            />
                        </div>
                    </div>


                    <div className="form-group col-md-6 mt-2">
                        <label className="font-weight-bolder">Cours</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    optioncours.filter(option => 
                                    option.value == this.state.lesson_id)
                                }
                                defaultValue={this.state.lesson_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            lesson_id : select.value
                                        });
                                    }
                                }
                                options={optioncours}
                            />
                        </div>
                    </div>

                    <div className="form-group col-md-6 mt-2">
                        <label className="font-weight-bolder">Titulaire</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    optionstaffs.filter(option => 
                                    option.value == this.state.staff_id)
                                }
                                defaultValue={this.state.staff_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            staff_id : select.value
                                        });
                                    }
                                }
                                options={optionstaffs}
                            />
                        </div>
                    </div>

                    <div className="col-md-4 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Max evalution</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="max_evaluation"  value={this.state.max_evaluation} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="trello"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Max exam</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="max_exam"  value={this.state.max_exam} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="box"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Indice bulletin</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="indice"  value={this.state.indice} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="activity"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                {
                    this.state.id &&(
                        <button onClick={() => this.delete()} className="btn btn-sm btn-outline-danger p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Supprimer</button>
                    )
                }
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    <button onClick={() => this.storeLesson()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default SectionclasselessonForm;