import axios from "axios";
import React, {Component} from "react";
import DisciplineForm from "./DisciplineForm";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";

class   Discipline extends Component{

    state = {
        isModal : false,
        loading : true,
        disciplines : [],
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Motif',
                field: 'motif',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Description',
                field: 'description',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'date_end',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Operateur',
                field: 'staff',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getDisciplines();
    }

    async getDisciplines(){
        
        this.setState({
            disciplines : [],
        })

        var disciplines = await axios.get('disciplines');
        if(disciplines.status === 200)
        {
            this.setState({
                disciplines : disciplines.data,
                loading : false
            })
        }
    }

    showModal = (discipline = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : discipline,
        })
        this.getDisciplines()
    }

    delete = (discipline) =>{
        axios.delete("disciplines/"+discipline).then((response) => {
            this.getDisciplines()
        }).catch((error)=>{})
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        
        var rows  = [];
        this.state.disciplines.map((discipline) => {
            var family_name = discipline.student ? discipline.student .user.family_name ? discipline.student.user.family_name : "" : "";
            var first_name = discipline.student ? discipline.student .user.first_name ? discipline.student.user.first_name : "" : "";
            var family_nameS = discipline.staff ? discipline.staff .user.family_name ? discipline.staff.user.family_name : "" : "";
            var first_nameS = discipline.staff ? discipline.staff .user.first_name ? discipline.staff.user.first_name : "" : "";
            var date_end = new Date(discipline.createdAt);
            date_end = date_end.getDate() + "-" + (date_end.getMonth() + 1) + "-" + date_end.getFullYear();
            
            rows.push({
                matricule : discipline.student && (<Link to={'/students/'+ discipline.student.id}  className="text-decoration-none">{discipline.student.matricule} <img src={discipline.student && (discipline.student.user.avatar)} className="rounded img-fluid" width={discipline.student && (discipline.student.user.avatar && ("30"))} /></Link>),
                student : discipline.student && (discipline.student.user.last_name + " " + family_name + " " + first_name),
                motif : discipline.motif,
                description : discipline.description,
                date_end : date_end,
                staff : discipline.staff && (discipline.staff.user.last_name + " " + family_nameS + " " + first_nameS),
                action : <span className="table_action"> 
                            {
                                this.handleCheck("Modifier discipline") == true && (   
                                    <button onClick={ () => this.showModal(discipline)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={"13"}></FeatherIcon> </button>
                                    )
                            }
                            {
                                this.handleCheck("Supprimer discipline") == true && (
                                    <button onClick={ () => this.delete(discipline.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"13"}></FeatherIcon> </button>
                                )
                            }       
                        </span>,

            })
        })
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | statics</title>
                </Helmet>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste  disciplines</h5>
                {
                    this.handleCheck("Ajouter discipline") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus"></FeatherIcon> Discipline</button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="overflow-hidden">
                {
                    rows.length > 0 && (
                        <Listprint columns={this.state.columns} rows={ rows} title={"Liste des congés"}/>
                    )
                }
            </div>

            <DisciplineForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
             

        </div>
        )
    }
}
export default Discipline;