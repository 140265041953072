import React, {Component} from "react";
import Chat from "./Chat";


class Notification extends Component{


    render(){
        return(
            <div>
                <h5 className="h5 font-monospace">Notification</h5>
                <div className="card shadow mb-4">
                    <div className="card-body">
                        <Chat display={true} />
                    </div>
                </div>
            </div>
        )
    }
}
export default Notification;