import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import Loading from "../../Loading";
import Select from 'react-select';
import Headerpdf from "../../users/Headerpdf";
import ReactToPrint from "react-to-print";

class   Reportcardfile extends Component{

    state = {
        loading : false,
        label: "",
        staff: "",
        sectionclasslessonId : null,
        sectionclasslessons : [],
        periodes : [],
        reportcardsectionclassleson : [],
    }

    componentDidMount(){
        this.getSectionclasslessons();
        this.getPeriodes()
    }

    async getSectionclasslessons(){
        var sectionclasslessons = await axios.get('sectionclasselessons');
        
        if(sectionclasslessons.status === 200)
        {
            this.setState({sectionclasslessons : sectionclasslessons.data, loading : true}) 
        }
    }

    async getReportcardsectionclasslesson(val){
        var reportcardsectionclassleson = await axios.get("reportcards/sectionclasselesson/" + val);
        if(reportcardsectionclassleson.status == 200)
        {
            this.setState({
                reportcardsectionclassleson : reportcardsectionclassleson.data,
                loading : true
            })
        }
    }

    async getPeriodes(){
        var periodes = await axios.get('periodes');
        
        if(periodes.status === 200)
        {
            this.setState({periodes : periodes.data}) 
        }
    }
    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }
     
    render(){
        var  date = [];
        var user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : false;

        var sectionclasslessons = [];
        this.state.sectionclasslessons.map((sectionclass) => {
            sectionclass.sectionclasselessons.map((sectionclasslesson) => {
                if(user && sectionclasslesson.staff && sectionclasslesson.staff.id == user.staff.id || this.handleCheck("Profil admin") == true )
                {
                    sectionclasslessons.push({
                        value : sectionclasslesson.id,
                        label : sectionclasslesson.lesson.entitled_lesson + " #" + sectionclass.class.short_entitled_class +" " + sectionclass.title + "# " + sectionclass.section.entitled_section,
                        staff : sectionclasslesson.staff.user.last_name + " " + sectionclasslesson.staff.user.first_name
                    })
                }
            })
            
        })
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Fiches des cours</h5>
            </div>
            <div className="row">
                <div className="col-12">
                    <Select className="form-control form-control-sm p-0 border-primary"
                        value = {
                            sectionclasslessons.filter(option => 
                            option.value == this.state.sectionclasslessonId)
                        }
                        defaultValue={ this.state.sectionclasslessonId }
                        onChange={
                            (select) => {
                                this.setState({
                                    sectionclasslessonId : select.value,
                                    staff : select.staff,
                                    label : select.label,
                                    loading : false,
                                    reportcardsectionclassleson : [],
                                });
                                this.getReportcardsectionclasslesson(select.value)
                            }
                        }
                        options={sectionclasslessons}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 ">
                    { this.state.loading ?  "" : <Loading /> }
                    <div className="text-end  mt-3">
                        <ReactToPrint
                            trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                            content={() =>  this.componentRef}
                        />
                    </div>
                    <div className="card shadow mb-3" ref={el => (this.componentRef = el)}>
                        <Headerpdf />
                        <div className="card-body">
                            <div className="font-monospace"><span className="fw-bolder">Titulaire :</span>{ this.state.staff }</div>
                            <div className="my-3 text-center font-monospace h6">Fiche cours { this.state.label } </div>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Eleve</th>
                                            {
                                                this.state.periodes.map((periode, index) => {
                                                    return(
                                                        <th>{ periode.periode}{ (periode.id != 3  && periode.id != 6 && periode.id != 7) &&(" période")  }</th>
                                                    )
                                                })
                                            }
                                            <th>Pourcent</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.reportcardsectionclassleson.map(student => {
                                                var total = 0, totalmax = 0;
                                                return(
                                                    <tr>
                                                        <td>{ student.user.last_name } { student.user.family_name } { student.user.first_name }</td>
                                                        {
                                                            this.state.periodes.map((periode, index) => {
                                                                return(
                                                                    student.reportcards.filter(data => data.periodeId == periode.id).map(reportcard => {
                                                                        var sum = 0, noteS=0;
                                                                        reportcard.notes.map(val =>{
                                                                            totalmax = totalmax + val.evaluation.max;
                                                                            sum += val.evaluation.max;
                                                                            noteS += val.note > 0 ? val.note : 0
                                                                        })
                                                                         total = noteS > 0 ? total + noteS : total;
                                                                        return(
                                                                            <td align="right" className={ sum > 0 ? sum / 2 >= noteS ? "text-danger" : "text-primary" : "" }>
                                                                                { noteS > 0 ? noteS.toFixed(1) + "/" : ""}{sum > 0 ? sum :""}
                                                                            </td>
                                                                        )
                                                                    })
                                                                )
                                                            })
                                                        }
                                                        <td align="right" className={ total > 0 ? ((total * 100) / totalmax) >= 50 ? "text-primary fw-bolder" : "text-danger fw-bolder" : ""}>{ total > 0 ? ((total * 100) / totalmax).toFixed(1)+"%" : "" } </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 ">
                    <div className="text-end  mt-3">
                        <ReactToPrint
                            trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                            content={() =>  this.componentRef1}
                        />
                    </div>
                    <div className="card shadow mb-3" ref={el => (this.componentRef1 = el)}>
                        <Headerpdf />
                        <div className="card-body">
                            <div className="font-monospace"><span className="fw-bolder">Titulaire :</span>{ this.state.staff }</div>
                            <div className="my-3 text-center font-monospace h6">Detail de la fiche { this.state.label } </div>
                            <div className="table-responsive">
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Eleve</th>
                                            {
                                                this.state.reportcardsectionclassleson.map(student => {
                                                    student.reportcards.map(reportcard => {
                                                        
                                                        reportcard.notes.map(val =>{
                                                            if(date.filter(value => value.id == val.evaluation.id).length == 0)
                                                            {
                                                                date.push({
                                                                    id : val.evaluation.id,
                                                                    date : val.evaluation.createdAt.slice(0, 10),
                                                                    periode : reportcard.periode.id
                                                                })  
                                                            }
                                                        })
                                                    }) 
                                                })
                                                          
                                            }
                                            
                                            {
                                                date.sort((a, b) => (( a.date > b.date) ? 1 : -1)).map(val => {
                                                    return(
                                                        <td className="small">{val.date} <span className="font-10">{(val.periode != 3 && val.periode != 6 && val.periode != 7) ? "("+val.periode + "P)" : ""}</span> </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.reportcardsectionclassleson.map(student => {
                                                return(
                                                    <tr>
                                                        <td>{ student.user.last_name } { student.user.family_name } { student.user.first_name }</td>
                                                        {
                                                            date.map(dat =>{ 
                                                                var total = 0, totalmax = 0;
                                                                student.reportcards.map(reportcard => {
                                                                    return(
                                                                        reportcard.notes.filter(val => val.evaluation.id == dat.id).map(val =>{
                                                                            totalmax =  val.evaluation.max;
                                                                            total = val.note
                                                                        })
                                                                    )
                                                                })
                                                                return(
                                                                    <td align="right" className={ total >= totalmax / 2 ? "text-primary" : "text-danger"} > { totalmax > 0 ? total +"/"+totalmax : "" } </td>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            
          
            

        </div>
        )
    }
}
export default Reportcardfile;