import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   LessonForm extends Component{

    state = {
        id : null,
        entitled_lesson : "",
        short_entitled_lesson : "",
        error : "",
        
        isModal : false,
        title : null,
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeLesson = () =>{
        var data = {
                entitled_lesson : this.state.entitled_lesson,
                short_entitled_lesson : this.state.short_entitled_lesson,
                operation : this.state.id ? "Editer" : "Ajouter",
            }

        var request = this.state.id ? axios.put('lessons/'+this.state.id, data) : axios.post('lessons', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    
    edit=()=>  {
        this.form()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            entitled_lesson: this.props.formData ? this.props.formData.entitled_lesson : "",
            short_entitled_lesson: this.props.formData ? this.props.formData.short_entitled_lesson : "",
            title : this.props.formData ? "Modifier le type de cours" : "Ajouter le type de cours",
            errors : {},
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">

                    <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Cours</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="entitled_lesson"  value={this.state.entitled_lesson} id="nom-id-icon" />
                                <div className="form-control-icon text-primary">
                                    <FeatherIcon icon="box"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Abréviation</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="short_entitled_lesson"  value={this.state.short_entitled_lesson} id="nom-id-icon" />
                                <div className="form-control-icon text-primary">
                                    <FeatherIcon icon="trello"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    <button onClick={() => this.storeLesson()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default LessonForm;