import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   ReportcardForm extends Component{

    state = {
        id : null,
        student_id : "",
        establishmentsectionclasselesson_id : "",
        reportcard_note : null,
        periode_id : "",
        error : "",
        
        isModal : false,
        title : null,

        students : [],
        periodes : [],
        establishmentsectionclasselessons : [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getStudent () {
        var request = await axios.get("students");
        if(request.status == 200)
        {
            this.setState({
                students : request.data
            })
        }
    }

    async getPeriodes () {
        var request = await axios.get("periodes");
        if(request.status == 200)
        {
            this.setState({
                periodes : request.data
            })
        }
    }

    async establishmentsectionclasselessons(){
        var request = await axios.get('establishmentsectionclasselessons');
        if(request.status === 200)
            this.setState({establishmentsectionclasselessons : request.data})
    }

    storeReportcard = () =>{
        var data = {
                student_id : this.state.student_id,
                establishmentsectionclasselesson_id : this.state.establishmentsectionclasselesson_id,
                reportcard_note : this.state.reportcard_note > 0 ? this.state.reportcard_note : null,
                periode_id : this.state.periode_id,
            }
            
        var request = this.state.id ? axios.put('reportcards/'+this.state.id, data) : axios.post('reportcards', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    
    edit=()=>  {
        this.form()
        this.getStudent()
        this.getPeriodes()
        this.establishmentsectionclasselessons()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            student_id: this.props.formData ? this.props.formData.schedule ? this.props.formData.schedule.id : "" : "",
            establishmentsectionclasselesson_id: this.props.formData ? this.props.formData.establishmentsectionclasselesson_id : "",
            reportcard_note : this.props.formData ? this.props.formData.reportcard_note : null,
            title :  "Attribution d'une note"  ,
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){

        var students = [], optionestablishmentsectionclasselessons = [], optionperiodes = [];
        this.state.establishmentsectionclasselessons.map((establishmentsectionclasselesson) => {
            optionestablishmentsectionclasselessons.push({ value : establishmentsectionclasselesson.id, label : establishmentsectionclasselesson.lesson.short_entitled_lesson + " "+ establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_class + " "+ establishmentsectionclasselesson.establishmentsectionclasse.title + " / " + establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_section  })
        })

        this.state.periodes.map((periode) => {
            optionperiodes.push({ value : periode.id, label : periode.periode })
        })

        this.state.students.map((student) =>{
            students.push({ value : student.id, label :  student.user.first_name +" "+student.user.last_name  + " "+ student.user.matricule })
        })
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">
                    <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                    <div className="form-group col-md-12 mt-2">
                        <label className="font-weight-bolder">élève  </label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm"
                                value = {
                                    students.filter(option => option.value == this.state.student_id)
                                }
                                defaultValue={this.state.student_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            student_id : select.value
                                        }); 
                                    }
                                }
                                options={students}
                            />
                        </div>
                        
                    </div>

                    <div className="col-6 mt-2 mt-2">
                        <div className="form-group ">
                            <label htmlFor="nom-id-icon">Période</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm"
                                    value = {
                                        optionperiodes.filter(option => option.value == this.state.periode_id)
                                    }
                                    defaultValue={this.state.periode_id}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                periode_id : select.value
                                            }); 
                                        }
                                    }
                                    options={optionperiodes}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Note</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control" placeholder="Note d'évaluation"  onChange={this.handleInput} name="reportcard_note"  value={this.state.reportcard_note} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="trello"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-group col-md-12 mt-2">
                        <label className="font-weight-bolder">Cours</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    optionestablishmentsectionclasselessons.filter(option => 
                                    option.value == this.state.establishmentsectionclasselesson_id)
                                }
                                defaultValue={this.state.establishmentsectionclasselesson_id}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            establishmentsectionclasselesson_id : select.value
                                        });
                                    }
                                }
                                options={optionestablishmentsectionclasselessons}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    <button onClick={() => this.storeReportcard()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default ReportcardForm;