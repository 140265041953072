import axios from "axios";
import React, {Component} from "react";
import {Link} from "react-router-dom";
import EvaluationForm from "./EvaluationForm";
import FeatherIcon from 'feather-icons-react';
import Loading from "../../Loading";

class   Evaluation extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : null,
        sectionclasse : "",
        sections : [],
        schedules : [],
    }

    componentDidMount(){
        this.getSections();
        this.getSchedules()
    }

    async getSections(){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({sections : sections.data}) 
        }
    }

    async getSchedules(){
        this.setState({schedules : []}) 
        var schedules = await axios.get('schedules');
        if(schedules.status === 200)
        {
            this.setState({schedules : schedules.data, loading : true}) 
        }
    }

    showModal = (evaluation = null, sectionclasse = null) => {
        this.setState({
            sectionclasse : sectionclasse,
            isModal : !this.state.isModal,
            formData : evaluation,
        })
        this.getSchedules()
    }

    delete = (evaluation) =>{
        axios.delete("evaluations/" + evaluation).then((response) => {
            this.getSchedules()
        }).catch((error)=>{})
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }
    
    render(){
        var user = JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')) : false;

        var evaluations =
        this.state.sections.map((section) =>{
            return(
                section.sectionclasses.map((sectionclass) =>{
                    if(this.state.schedules.filter(schedule =>  schedule.periodeId == 3 && schedule.sectionclasselesson.sectionclass.id == sectionclass.id  && user && schedule.sectionclasselesson.staffId == user.staff.id ||  schedule.periodeId == 6 && schedule.sectionclasselesson.sectionclass.id == sectionclass.id  && user && schedule.sectionclasselesson.staffId == user.staff.id || schedule.periodeId == 3 && schedule.sectionclasselesson.sectionclass.id == sectionclass.id && this.handleCheck("Profil admin") == true || schedule.periodeId == 6 && schedule.sectionclasselesson.sectionclass.id == sectionclass.id && this.handleCheck("Profil admin") == true ).length > 0)
                    {
                        return(
                            <li className="widget-todo-item list-group-item m-2 mb-4 border rounded-3">
                                <div
                                    className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                    <div className="widget-todo-title-area d-flex align-items-center">
                                    {
                                        this.handleCheck("Ajouter evaluation") == true && (
                                        <div className="btn-toolbar mb-2 mb-md-0">
                                            <div className="btn-group me-2">
                                                <button type="button" className="btn btn-sm text-primary" onClick={() => this.showModal(null, sectionclass)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                                            </div>
                                        </div>
                                        )
                                    }
                                    <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                                        <label htmlFor={sectionclass.id}><span className="widget-todo-title ml-50">{sectionclass.class.short_entitled_class} <span className="text-primary">{sectionclass.title}</span> { section.short_entitled_section} </span></label>
                                    </div>
                                    
                                    <div className="widget-todo-item-action d-flex align-items-center">
                                        { section.short_entitled_section} <FeatherIcon icon="list" size="16" className='cursor-move text-dark'></FeatherIcon>
                                    </div>
                                </div>
                                <div className="row m-2">
                                    {/* <div className="userIdentity">
                                        <div className="text-center h6">
                                            <label htmlFor={schedule.id}><span className="widget-todo-title ml-50">Horaire {schedule.classe.short_entitled_class} {schedule.classe.entitled_class} <span className="text-primary">{schedule.title}</span> { schedule.section.short_entitled_section} </span></label>
                                        </div>
                                    </div> */}
                                    
                                        {
                                            this.state.schedules.filter(schedule =>  schedule.periodeId == 3 && schedule.sectionclasselesson.sectionclass.id == sectionclass.id  && user && schedule.sectionclasselesson.staffId == user.staff.id ||  schedule.periodeId == 6 && schedule.sectionclasselesson.sectionclass.id == sectionclass.id  && user && schedule.sectionclasselesson.staffId == user.staff.id || schedule.periodeId == 3 && schedule.sectionclasselesson.sectionclass.id == sectionclass.id && this.handleCheck("Profil admin") == true || schedule.periodeId == 6 && schedule.sectionclasselesson.sectionclass.id == sectionclass.id && this.handleCheck("Profil admin") == true ).map((schedule) => {
                                                return (
                                                        
                                                    schedule.evaluations.map((evaluation) => {
                                                        var 
                                                            percent = 0;
                                                            
                                                            evaluation.notes.map((note) => {
                                                                percent = note.note + percent;
                                                            })
                                                            if(percent > 0)
                                                            {
                                                                percent = (percent * 100 ) /  (evaluation.max * evaluation.notes.length)
                                                            }
                                                            return(
                                                                <div className="col-md-3" key={evaluation.id}>
                                                                    <div className="card shadow mb-3">
                                                                        <div className="card-body">
                                                                            <div
                                                                                className="widget-todo-title-wrapper align-items-center mb-50">
                                                                                    
                                                                                    {/* <div className="text-end">
                                                                                        {
                                                                                            evaluation.schedule.periode.periode
                                                                                        }
                                                                                    </div> */}
                                                                                    <div className="widget-todo-title-area  align-items-center">
                                                                                        <FeatherIcon icon="anchor" className='cursor-move'></FeatherIcon>
                                                                                        <label htmlFor={evaluation.id}>
                                                                                            <span className="widget-todo-title ml-50">
                                                                                                <Link to={"/evaluations/" + evaluation.id+"/notes"} className="d-inline text-decoration-none"> 
                                                                                                    <span className="text-primary text-small small"  style={{ fontSize : 11 + "px"}}> {  schedule.periodeId +" période" } # { schedule.year.year  } </span>
                                                                                                    {/* {  schedule.establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_class + " "+ schedule.establishmentsectionclasselesson.establishmentsectionclasse.title + " "+ schedule.establishmentsectionclasselesson.establishmentsectionclasse.short_entitled_section}  */}
                                                                                                </Link> 
                                                                                            </span>
                                                                                            
                                                                                        </label>
                                                                                        <div className="fw-bold text-center"> {schedule.sectionclasselesson.lesson.short_entitled_lesson }  / <span className="text-danger">{ evaluation.max }</span></div>
                                                                                        <div className="text-center fst-italic fw-lighter"> {evaluation.reason_evaluation}
                                                                                            <br />
                                                                                            <span className="small">{ evaluation.notes.length > 0 ? evaluation.notes.length + " / " + schedule.sectionclasselesson.sectionclass.students.length + " élèves" : " / " + schedule.sectionclasselesson.sectionclass.students.length + " élèves"}</span>
                                                                                        </div>
                                                                                        <div className="text-end fst-italic" style={{ fontSize : 11 + "px"}}>{ schedule.date_start ? schedule.date_start : (evaluation.created_at) }</div>
                                                                                    </div>
                                                                                    {
                                                                                        percent === 0 && (<div className="widget-todo-item-action d-flex align-items-center">
                                                                                            {
                                                                                            this.handleCheck("Modifier evaluation") == true && (
                                                                                                <FeatherIcon icon="edit-2" size="16" className='cursor-move text-primary me-2' type="button" onClick={() => this.showModal(evaluation, sectionclass)}></FeatherIcon> 
                                                                                            )}{
                                                                                            this.handleCheck("Supprimer evaluation") == true && (
                                                                                                <FeatherIcon icon="delete" size="16" className='cursor-move text-danger' type="button" onClick={() => this.delete(evaluation.id)}></FeatherIcon>
                                                                                            )
                                                                                        }</div>
                                                                                        )
                                                                                    }
                                                                                
                                                                                    <div className="text-end ">
                                                                                        <span className={ percent < 50 ? "border border-1 border-danger rounded rounded-circle" :  "border border-1 border-primary rounded rounded-circle" }><span className={ percent < 50 ? "text-danger fw-bold p-2" : "text-primary fw-bold p-2"}>{ percent.toFixed(1)  }%</span></span>
                                                                                        {
                                                                                            this.handleCheck("Ajouter note") == true && (
                                                                                                <Link to={"/evaluations/" + evaluation.id+"/notes"} className="d-inline"><FeatherIcon icon="activity" className='cursor-move d-inline text-primary mr-3' type="button" title="notes"></FeatherIcon></Link>
                                                                                            )
                                                                                        }{this.handleCheck("Ajouter note") == true && (
                                                                                                <Link to={"/evaluations/" + evaluation.id +"/questions"} className="mx-3 d-inline"><FeatherIcon icon="clipboard" className='cursor-move d-inline' type="button" title="questions de l'evaluation"></FeatherIcon></Link>
                                                                                            )
                                                                                        }
                                                                                    </div> 
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    )
                                                })
                                                
                                                
                                        }
                                        
                                </div>
                            </li>
                        )
                    }
                })
            )
        })

       
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Les examens</h5>
                
            </div>

            <div className=" mb-4 ">
                <div className="row container-fluid">
                    { this.state.loading ?  evaluations : <Loading /> }
                </div>
            </div>
            
            <EvaluationForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                sectionclasse={this.state.sectionclasse} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Evaluation;