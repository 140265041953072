import axios from "axios";
import React, {Component} from "react";
import PresenceForm from "./PresenceForm";
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from "react-to-print";
import Headerpdf from "../../users/Headerpdf";
import UserIdentity from "../../users/UserIdentity";

class   Presence extends Component{

    state = {
        time_end : null,
        presences : [],
        months : [],
    }

    componentDidMount(){
        var commitment_date = new Date();
        commitment_date = commitment_date.getMonth() + 1;
        this.setState({
            monthId : commitment_date
        })
        this.getMonths();
        this.getPresences(commitment_date);
    }

    async getMonths(){
        var months = await axios.get("months");
        if(months.status === 200)
        {
            this.setState({
                months : months.data
            })
        }
    }

    handleInputM = (e) => {
        this.getPresences(e.target.value);
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getPresences(monthId){
        this.setState({ presences : [] })
        var presences = await axios.get('presences/' + monthId);
        if(presences.status === 200)
        {
            this.setState({presences : presences.data}) 
        }
    }

    showModal = () => {
        this.getPresences(this.state.monthId);
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeEnd = (id) => {
        axios.put("presences/" + id, { time_end : this.state.time_end})
        this.getPresences(this.state.monthId)
    }

    delete = (presence) =>{
        axios.delete("presences/" + presence).then((response) => {
            this.getPresences(this.state.monthId)
        }).catch((error)=>{})
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        var presences =
        this.state.presences.map((presence) => {
            var date_start =  new Date(presence.date);
            var mois = date_start.getMonth()+1;
                mois = mois >= 10 ? mois : "0"+mois;
            var jour = date_start.getDate() >= 10 ? date_start.getDate() : "0"+date_start.getDate();
            date_start = date_start.getFullYear()  + "-" + (mois) + "-" + jour;

            return(
                <tr key={presence.id}>
                    <td>{presence.staff.matricule} {presence.staff.user.last_name} {presence.staff.user.first_name}</td>
                    <td>{ date_start }</td>
                    <td>{presence.time_start }</td>
                    <td>
                    {
                        presence.time_end != "00:00:00" ? presence.time_end : 
                        <div className="row table_action">
                            <div className="col-10">
                                <input type="time" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="time_end" id="nom-id-icon" />
                            </div>
                            <div className="col-2">
                                <button onClick={() => this.storeEnd(presence.id)} className="mt-2 btn btn-sm btn-outline-primary border border-0 p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon></button>
                            </div>
                        </div>
                    }
                    </td>
                    <td><button onClick={() => this.delete(presence.id)} className="mt-2 btn btn-sm btn-outline-danger p-0 px-1 table_action"><FeatherIcon icon="delete"></FeatherIcon></button></td>
                </tr>
            )
        })
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Presence du personnel</h5>
            </div>
            
            <div className="card shadow mb-2">
                <div className="card-body">
                    <PresenceForm showModal={this.showModal} />
                </div>
            </div>
            <div className="card shadow mb-2">
                <div className="card-body">
                <div className="text-end">
                    <select name="monthId" value={ this.state.monthId} className="me-2 text-primary" onChange={this.handleInputM}>
                        {
                            this.state.months.map((month) => {
                                return(
                                    <option value={month.id}>{ month.month }</option>
                                )
                            })
                        }
                    </select>
                    <ReactToPrint
                        trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                        content={() =>  this.componentRef}
                    />
                </div>
                    <div className=" mb-4 overflow-auto mt-2">
                        <div className="table-responsive"  ref={el => (this.componentRef = el)}>
                            <Headerpdf />
                            <UserIdentity title={ "Presence personnel " + this.state.monthId + " ° mois"} />
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>Personnel</th>
                                        <th>Date</th>
                                        <th>Heure d'arivée</th>
                                        <th>Heure de depart</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { presences}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            

        </div>
        )
    }
}
export default Presence;