import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   ScheduleForm extends Component{

    state = {
        id : null,
        lesson : "",
        staff : "",
        day : null,
        periode : null,

        date_start : null,
        date_end : null,
        time_start : null,
        time_end : null,
        scolary_year : null,

        error : "",
        
        staffs : [],
        sectionclasselessons : [], //lesson
        days : [],
        schedulecategories : [],
        periodes : [],
        years : [],
        sms : 1,

        isModal : false,
        title : null,
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getYear(){
        var data=[], years = await axios.get('years');
        if(years.status === 200)
        {
            years.data.map((year) => {
                data.push({value : year.id, label : year.year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    storeSchedule = () =>{
        var data = {
                sectionclasselessonId: this.state.lesson,
                staffId : this.state.staff,
                dayId : this.state.day ? this.state.day : null,
                periodeId : this.state.periode ? this.state.periode : null,
                date_start : this.state.date_start ? this.state.date_start : null,
                date_end : this.state.date_end ? this.state.date_end : null,
                time_start : this.state.time_start ? this.state.time_start : null,
                time_end : this.state.time_end ? this.state.time_end : null,
                yearId : this.state.scolary_year ? this.state.scolary_year : null,
                operation : this.state.id ? "Editer" : "Ajouter"
            }

        var request = this.state.id ? axios.put('schedules/'+this.state.id, data) : axios.post('schedules', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async sectionclasselessons(){
        var request = await axios.get('sectionclasselessons');
        if(request.status === 200)
            this.setState({sectionclasselessons : request.data})
    }

    async staffs(){
        var request = await axios.get('staffs');
        if(request.status === 200)
            this.setState({staffs : request.data})
    }

    async days(){
        var request = await axios.get('days');
        if(request.status === 200)
            this.setState({days : request.data})
    }


    async periodes(){
        var request = await axios.get('periodes');
        if(request.status === 200)
            this.setState({periodes : request.data})
    }   
    
    edit=()=>  {
        this.form()
        this.staffs()
        this.sectionclasselessons()
        this.days()
        this.periodes()
        this.getYear()
    }

    form=()=>{
        
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            max_exam: this.props.formData ? this.props.formData.max_exam : null,
            max_evaluation: this.props.formData ? this.props.formData.max_evaluation : null,
            lesson: this.props.formData ? this.props.formData.sectionclasselesson ? this.props.formData.sectionclasselesson.id : "" : "",
            staff: this.props.formData ? this.props.formData.staff ? this.props.formData.staff.id : "" : "",
            periode: this.props.formData ? this.props.formData.periodeId : null,
            day: this.props.formData ? this.props.formData.dayId : null,
            date_start : this.props.formData? this.props.formData.date_start : "",
            date_end : this.props.formData? this.props.formData.date_end : "",
            time_start : this.props.formData? this.props.formData.time_start : "",
            time_end : this.props.formData? this.props.formData.time_end : "",
            scolary_year : this.props.formData ? this.props.formData.yearId : null,
            title : this.props.formData ? "Modifier la planification de l'horaire" : "Planification de l'horaire",
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    sms = (schendule) => {
        axios.get('schendules/sms/'+schendule).then((response) => {
            this.showModal()
        }).catch((error) => {
            this.setState({
                error : {
                    sms : "sms non envoyé"
                }
            })
        })
    }

    render(){
        var optionsectionclasselessons = [], optionstaffs = [], optiondays = [], optionperiodes = [];
        
        this.state.sectionclasselessons.map((sectionclasselesson) => {

            if(this.props.sectionclasse && this.props.sectionclasse.id == sectionclasselesson.id)
            {
                sectionclasselesson.sectionclasselessons.map((lesson) => {
                    optionsectionclasselessons.push({ value : lesson.id, label : lesson.lesson.entitled_lesson  , staff : lesson.staff.id })
                })
            }
        })

        this.state.staffs.map((staff) => {
            optionstaffs.push({ value : staff.id, label : staff.user.first_name + " "+staff.user.last_name + " "+staff.user.matricule})
        })

        this.state.days.map((day) => {
            optiondays.push({ value : day.id, label : day.day })
        })

        this.state.periodes.map((periode) => {
            optionperiodes.push({ value : periode.id, label : periode.periode })
        })

        var optionsms = [];
        optionsms.push({
            value : 1, label:"Oui"
        })
        optionsms.push({
            value : 0, label:"Non"
        })

        return(
            <div>
                <Modal 
                    show={this.props.isModal} 
                    onHide={this.props.showModal}
                    size="lg"
                    onEntered={this.edit}
                    backdrop={"static"}>
                    <Modal.Header>
                        <Modal.Title className="h6 font-monospace">{ this.state.title} { this.props.sectionclasse ? this.props.sectionclasse.class.short_entitled_class + " " + this.props.sectionclasse.title : "" }</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="row">
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                         
                        <div className="form-group col-md-5 mt-2">
                            <label className="font-weight-bolder">Cours en { this.props.sectionclasse ? this.props.sectionclasse.class.short_entitled_class + " " + this.props.sectionclasse.title : "" }</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm p-0"
                                    value = {
                                        optionsectionclasselessons.filter(option => 
                                        option.value === this.state.lesson)
                                    }
                                    defaultValue={this.state.lesson}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                lesson : select.value,
                                                staff : select.staff
                                            });
                                        }
                                    }
                                    options={optionsectionclasselessons}
                                />
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Année scolaire</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        this.state.years.filter(option => 
                                        option.value == this.state.scolary_year)
                                    }
                                    defaultValue={this.state.scolary_year}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                scolary_year : select.value
                                            });
                                        }
                                    }
                                    options={this.state.years}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-3 mt-2">
                            <label className="font-weight-bolder">Periode </label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm p-0"
                                    value = {
                                        optionperiodes.filter(option => 
                                        option.value === this.state.periode)
                                    }
                                    defaultValue={this.state.periode}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                periode : select.value
                                            });
                                        }
                                    }
                                    options={optionperiodes}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-8 mt-2">
                            <label className="font-weight-bolder">Tutilaire</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm p-0"
                                    value = {
                                        optionstaffs.filter(option => 
                                        option.value === this.state.staff)
                                    }
                                    defaultValue={this.state.staff}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                staff : select.value
                                            });
                                        }
                                    }
                                    options={optionstaffs}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-4 mt-2">
                            <label className="font-weight-bolder">Jour</label>
                            <div className="input-group">
                                <Select className="form-control form-control-sm p-0"
                                    value = {
                                        optiondays.filter(option => 
                                        option.value === this.state.day)
                                    }
                                    defaultValue={this.state.day}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                day : select.value
                                            });
                                        }
                                    }
                                    options={optiondays}
                                />
                            </div>
                        </div>

                        <div className="col-md-8 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Date debut</label>
                                <div className="position-relative mt-1">
                                    <input type="date" className="form-control form-control-sm"   onChange={this.handleInput} name="date_start"  value={this.state.date_start} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="clock"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Heure debut</label>
                                <div className="position-relative mt-1">
                                    <input type="time" className="form-control form-control-sm"   onChange={this.handleInput} name="time_start"  value={this.state.time_start} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="watch"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-8 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Date cloture</label>
                                <div className="position-relative mt-1">
                                    <input type="date" className="form-control form-control-sm"   onChange={this.handleInput} name="date_end"  value={this.state.date_end} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="clock"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Heure cloture</label>
                                <div className="position-relative mt-1">
                                    <input type="time" className="form-control form-control-sm"   onChange={this.handleInput} name="time_end"  value={this.state.time_end} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="watch"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                        <div className="form-group col-md-6 mt-2 d-none">
                            <label className="font-monospace fw-normal">Notifier par sms</label>
                            <div className="input-group">
                                <Select 
                                    className="form-control form-control-sm p-0 all"
                                        value = {
                                            optionsms.filter(option => 
                                            option.value == this.state.sms)
                                        }
                                        defaultValue={this.state.sms}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    sms : select.value
                                                });
                                            }
                                        }
                                        options={optionsms}
                                    />
                                {
                                    this.state.id && (
                                        <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={() => this.sms(this.state.id)}>{this.props.formData ? this.props.formData.notifiant > 0 ? this.props.formData.notifiant  : "0" : "0"}<FeatherIcon icon="mail" ></FeatherIcon></button>
                                    )
                                }
                            </div>
                        </div>
                         

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm  p-0 px-1 rounded-3"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        {
                            this.handleCheck("Ajouter horaire") == true || this.handleCheck("Modifier horaire") == true ? (
                                <button onClick={() => this.storeSchedule()} className="btn btn-sm btn-outline-primary p-0 px-1 rounded-3"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                            ) : ""
                        }
                        </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
export default ScheduleForm;