import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import Loading from "../../Loading";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class   Note extends Component{

    state = {
        isModal : false,
        formData : null,
        loading : false,
        evaluation : null,
        sectionclasse : [],
        note : [],
        errors : {},
    }

    componentDidMount(){
        this.getNotes();
    }

    async getNotes(){
        this.setState({ evaluation : null, loading : false})
        var evaluation = await axios.get('evaluations/' + this.props.match.params.evaluation);
        if(evaluation.status === 200)
        {
            this.setState({evaluation : evaluation.data, loading : true}) 
        }
    }


    handleInput = (e) => {
        this.state.note[e.target.name] = e.target.value;
    }

    async storeNote() {
        this.setState({ evaluation : null,  loading : false})
        var note = [];
        this.state.evaluation.schedule.sectionclasselesson.sectionclass.students.map((student) => {
            if(this.state.note[student.id] >= 0 && this.state.note[student.id]<= this.state.evaluation.max )
            {
                note.push({ studentId : student.id, note : this.state.note[student.id] });
                this.state.note[student.id] = ""
            }
        })
        
        var data = {
            evaluationId : this.state.evaluation.id,
            notes : note,
        };
        await axios.post('evaluations/notes', data).then((repons) =>{
            
            this.setState({ evaluation : null,  loading : false})
            toast(repons?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((err) => {
            console.log(err)    
            toast(err.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        });
        
        this.getNotes()
    }
    
  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){ 

        var commitment_date = new Date(this.state.evaluation ? this.state.evaluation.schedule ? this.state.evaluation.schedule.date_start ? this.state.evaluation.schedule.date_start : this.state.evaluation.createdAt : null : null );
        commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();                      

        var students = "", noteM = 0;
        
        this.state.evaluation && (
            this.state.evaluation.schedule.sectionclasselesson.sectionclass &&(
                students = 
                // this.state.sectionclasse.students.filter(student => student.operation === "Ajouter" && student.scolary_year === this.state.evaluation.schedule.scolary_year || student.operation === "Editer" && student.scolary_year === this.state.evaluation.schedule.scolary_year).map((student) => {
                    this.state.evaluation.schedule.sectionclasselesson.sectionclass.students.sort((a, b) => (a.user.last_name > b.user.last_name ? 1 : -1)).map((student) => {
                       noteM = 0
                    return(
                        <li className="widget-todo-item list-group-item m-2 border rounded-3" key={student.id}>
                            <div
                                className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                <div className="widget-todo-title-area d-flex align-items-center">
                                    <img src={ student.user.avatar }  className="rounded img-fluid" width={30} />
                                    <label htmlFor={student.id} className={student.operation == "Archiver" ? "text-success" : student.operation == "Supprimer" ? "text-danger" : "" }><span className="widget-todo-title ml-50">{ student.matricule } { student.user.last_name } { student.user.family_name } { student.user.first_name }</span>
                                    <small className="small"> { student.year ? student.year.year :"" }</small>
                                        {
                                            this.state.evaluation.notes.filter(data => data.reportcard.studentId == student.id).map((note) => {
                                                {/* console.log(note) */}
                                                    noteM = this.state.evaluation.max;
                                                return(
                                                    <span className="text-danger" key={note.id}> { note.note }/{this.state.evaluation.max}</span>
                                                )
                                            })
                                        }
                                    </label>
                                </div>
                                
                                <div className="widget-todo-item-action d-flex align-items-center">
                                    {
                                        student.yearId == this.state.evaluation.schedule.yearId &&(
                                            this.handleCheck("Ajouter note") == true && noteM == 0 || this.handleCheck("Modifier note") == true && noteM > 0  ?(
                                                <input type="number" className="form-control form-control-sm"  name={ student.id } value={ this.state.note[student.id] ? this.state.note[student.id] : null } onChange={this.handleInput}  />
                                            ) : ""
                                        )
                                    }
                                </div>
                            </div>
                        </li>
                    )
                })
            )
        )
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Note { this.state.evaluation ? this.state.evaluation.category : ""} </h5>
            </div>

            <div className=" mb-4 overflow-auto">
                 
                <div className="">
                    {
                        this.state.evaluation && (
                            <div
                                className="widget-todo-title-wrapper card shadow  text-center align-items-center mb-3">
                                <div className="widget-todo-title-area  align-items-center">
                                    <FeatherIcon icon="cloud-drizzle" className='cursor-move'></FeatherIcon>
                                    <label htmlFor={this.state.evaluation.id}><span className="widget-todo-title ml-50">{ this.state.evaluation.schedule.periode.id  + "P " + this.state.evaluation.schedule.sectionclasselesson.sectionclass.class.short_entitled_class + " "+ this.state.evaluation.schedule.sectionclasselesson.sectionclass.title + " " + this.state.evaluation.schedule.sectionclasselesson.sectionclass.section.short_entitled_section }  </span></label>
                                    <div className="fw-bold text-center">{this.state.evaluation.schedule.sectionclasselesson.lesson.entitled_lesson } / <span className="text-danger">{ this.state.evaluation.max }</span></div>
                                    <div className="text-center fst-italic fw-lighter"> {this.state.evaluation.reason_evaluation} </div>
                                    <div className="text-end fst-italic" style={{ fontSize : 11 + "px"}}>{ commitment_date  } # { this.state.evaluation.schedule.year.year  }</div>
                                </div>
                                {/* <div className="widget-todo-item-action text-end">
                                    <FeatherIcon icon="edit" size="20" className='cursor-move text-white bg-primary m-1 p-1 rounded rounded-circle' type="button" onClick={() => this.showModal(this.state.evaluation)}></FeatherIcon>
                                </div> */}
                            </div>
                        )
                    }
                    

                    <ul className="widget-todo-list-wrapper p-0">
                        <li className="widget-todo-item list-group-item m-2 border-0 rounded-3" key="12">
                            <div
                                className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                <div className="widget-todo-title-area d-flex align-items-center fw-bold">
                                    Elèves
                                </div>
                                
                                <div className="widget-todo-item-action d-flex align-items-center fw-bold">
                                    Points
                                </div>
                            </div>
                        </li>
                        { this.state.loading ? students : <Loading /> }
                    </ul>
                    <div className="text-end">
                        <Link to="/evaluations" className="btn btn-sm btn-outline-secondary  p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Retour</Link>
                        <button onClick={() => this.storeNote()} className="btn btn-sm btn-outline-primary  p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </div>
                </div>
            </div>
            
            

        </div>
        )
    }
}
export default Note;