import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Listprint from "../../Listprint";
import Loading from "../../Loading";

class Reportcardfail extends Component{

    state = {
        loading : false,
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Pourcent',
                field: 'studentsuccess',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Classe',
                field: 'classe',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Section',
                field: 'section',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            },
        ],
        sectionclasses : []
    }

    componentDidMount(){
        this.getSectionclasses()
    }

    async getSectionclasses(){
        var sectionclasses = await axios.get('sectionclassestat/report');
        if(sectionclasses.status === 200)
        {
            this.setState({sectionclasses : sectionclasses.data, loading : true}) 
        }
    }

    render(){
        var   max=0, note=0, labels = [], data = [], rows =[];
        this.state.sectionclasses.map(( sectionclasse) => {

            sectionclasse.students.filter(val => ( (val.studentsuccess * 100 ) / val.studentmax) < 50).sort((a, b) => ( ( (a.studentsuccess * 100 ) / a.studentmax) < ((b.studentsuccess * 100 ) / b.studentmax) ? 1 : -1 ) ).map((student) => {
                rows.push({
                        matricule : student.user && (<Link to={'/students/'+student.id}  className="text-decoration-none text-primary">{student.matricule}</Link>),
                        student : student.user && (student.user.last_name +" "+ student.user.family_name  +" "+ student.user.first_name ),
                        studentsuccess : student.studentmax > 0 ? ((student.studentsuccess * 100 ) / student.studentmax).toFixed(1)+"%" : "0%",
                        classe : sectionclasse.class.short_entitled_class + " " + sectionclasse.title,
                        section : sectionclasse.section.short_entitled_section,
                        created_at : student.createdAt.slice(0, 10),
                   });
            })
        })
        
        return(
            <div>
                <div className=" align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h5 className="h5 font-monospace">Les échecs</h5>
                </div>
                {
                    !this.state.loading && (<Loading />)
                }
                
                {
                    rows.length > 0 && (
                        <Listprint columns={this.state.columns} rows={rows} title={"Les échecs"}/>
                    )
                } 
                

            </div>
        )
    }
}
export default Reportcardfail