import axios from "axios";
import React, {Component} from "react";
import SectionclasselessonForm from "./SectionclasselessonForm";
import FeatherIcon from 'feather-icons-react';
import Lesson from "../lessons/Lesson";
import Loading from "../../Loading";

class   Sectionclasselesson extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : null,
        sectionclasse : null,
        sectionclasses : [],
    }

    componentDidMount(){
        this.getSectionclasselessons();
    }

    async getSectionclasselessons(){
        var sectionclasses = await axios.get('sectionclasselessons');
        if(sectionclasses.status === 200)
        {
            this.setState({sectionclasses : sectionclasses.data, loading : true}) 
        }
    }

    showModal = (sectionclasselesson = null, sectionclasse = null ) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : sectionclasselesson,
            sectionclasse : sectionclasse
        })
        this.getSectionclasselessons()
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }
    
    render(){
        var sectionclasses =
        this.state.sectionclasses.sort((a,b) => (a.section.short_entitled_section > b.section.short_entitled_section ) ? 1 : -1).map((sectionclasse) => {
            
            return(
                <li className="widget-todo-item list-group-item m-2 border rounded-3" key={sectionclasse.id}>
                    <div
                        className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                        <div className="widget-todo-title-area d-flex align-items-center">
                            <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                            <label htmlFor={sectionclasse.id}><span className="widget-todo-title ml-50">{sectionclasse.class.short_entitled_class} <span className="text-primary">{sectionclasse.title}</span> </span></label>
                        </div>
                        <div className="widget-todo-item-action d-flex align-items-center">
                            {sectionclasse.section.short_entitled_section} <FeatherIcon icon="list" size="16" className='cursor-move text-dark'></FeatherIcon>
                        {
                            this.handleCheck("Ajouter cours") == true && (
                                <button type="button" className="btn btn-sm text-primary" onClick={() => this.showModal(null, sectionclasse.id)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                            )
                        }
                        </div>
                    </div>
                    <div>
                        <ul className="widget-todo-list-wrapper p-0">
                            {
                                sectionclasse.sectionclasselessons.sort((a, b) => (a.lesson.short_entitled_lesson > b.lesson.short_entitled_lesson) ? 1 : -1).map((sectionclasselesson) => {
                                    return(
                                        <li className="widget-todo-item list-group-item m-2 border rounded-3" key={sectionclasselesson.id}>
                                            <div
                                                className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                                <div className="widget-todo-title-area d-flex align-items-center">
                                                    <FeatherIcon icon="bookmark" className='cursor-move'></FeatherIcon>
                                                    <label htmlFor={sectionclasselesson.id} type="button" onClick={() => this.showModal(sectionclasselesson, sectionclasse.id)}><span className="widget-todo-title ml-50">{sectionclasselesson.lesson.entitled_lesson}<span className="text-primary">{sectionclasselesson.indice > 0 ? " ." + sectionclasselesson.indice : ""}</span> </span></label>
                                                </div>

                                                <div>
                                                    <a href={'http://evivesomarh.com/staffs/'+sectionclasselesson.staff.id}  className="text-decoration-none">
                                                        <img src={ sectionclasselesson.staff.user.avatar} title={sectionclasselesson.staff.matricule + " "+sectionclasselesson.staff.user.first_name} className="rounded-circle" width="30" />
                                                        {sectionclasselesson.staff.matricule + " "+sectionclasselesson.staff.user.last_name}
                                                    </a>
                                                </div>

                                                <div className="widget-todo-item-action d-flex align-items-center">
                                                    {sectionclasselesson.max_evaluation} <FeatherIcon icon="grid" size="16" className='d-inline mx-1'></FeatherIcon>{sectionclasselesson.max_exam}
                                                    {
                                                        this.handleCheck("Modifier cours") == true && (
                                                          <FeatherIcon icon="edit-2" size="16" className={ sectionclasselesson.operation == 'Supprimer' ? 'cursor-move text-danger' : 'cursor-move text-primary'} type="button" onClick={() => this.showModal(sectionclasselesson)}></FeatherIcon>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </li>
            )
        })
        return(
            <div>

            <div className="row">
                <div className="col-md-8">
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h5 className="h5 font-monospace">Cours par classe</h5>
                        {
                            this.handleCheck("Ajouter cours") == true && (
                                <div className="btn-toolbar mb-2 mb-md-0">
                                    <div className="btn-group me-2">
                                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon> Cours</button>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <ul className="widget-todo-list-wrapper p-0">
                        { this.state.loading ?  sectionclasses  : <Loading />}
                    </ul>
                </div>
                {
                    this.handleCheck("Afficher cours") == true && (
                        <div className="col-md-4">
                            <div className="card shadow mb-2">
                                <div className="card-body">
                                    <Lesson />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            
            <SectionclasselessonForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                sectionclasse={this.state.sectionclasse}
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Sectionclasselesson;