import React, {Component} from "react";
import { Line, Bar } from 'react-chartjs-2';
import ReactToPrint from "react-to-print";
import FeatherIcon from 'feather-icons-react';
import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import Headerpdf from "../../users/Headerpdf";

ChartJS.register(CategoryScale, LineElement, PointElement, LinearScale, Title);

class   ReportLine extends Component{

    state = {
        labels: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
        data: [],
        datasets: [
          {
            label: 'Rainfall',
            fill: false,
            lineTension: 0.5,
            backgroundColor: 'rgba(75,192,192,1)',
            borderColor: '#045fc0',
            borderWidth: 2,
            data: [47, 35, 66, 52, 33, 44, 20, 66, 12, 22, 44, 59]
          }
        ]
      }

      componentDidMount(){
        if(this.props.data)
        {
          this.setState({
              data : this.props.data,
              datasets: [
                {
                  label: '%',
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'rgba(75,192,192,1)',
                  borderColor: '#045fc0',
                  borderWidth: 2,
                  data: this.props.data
                }
              ]
          })
        }

        if(this.props.labels)
        {
          this.setState({
            labels : this.props.labels
          })
        }
      }

    render(){
        
        return(
        <div>
          <div className="text-end">
              <ReactToPrint
                  trigger={() => <button className="btn btn-sm btn-outline-primary rounded rounded-3" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                  content={() =>  this.componentRef}
              />
          </div>
          <div ref={el => (this.componentRef = el)}>
            <div className="m-2 ml-4">
              <Headerpdf />
              <div className="userIdentity">
                <div className="text-center h6 mx-2">{ this.props.title} </div>
            </div>
          {
            this.state.data.length > 0 && (
            <Line
                data={this.state}
                options={{
                    title:{
                    display:true,
                    text:'Données',
                    fontSize:20
                    },
                    legend:{
                    display:true,
                    position:'right'
                    }
                }}
                />
              )
            }
            </div>
          </div>
        </div>
        )
    }
}
export default ReportLine;