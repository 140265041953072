import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import { Helmet } from 'react-helmet';
import Loading from "../../Loading";
import ReportLine from "../reports/ReportLine";

class Archive extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : "",
        archives : [],
        isModal : false,
        formData : {},
        classe : "",
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'pourcent',
                field: 'studentsuccess',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Responsable',
                field: 'responsible',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Archiver',
                field: 'created_at',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getArchives();
    }

    async getArchives(){
        var archives = await axios.get('years');
        if(archives.status === 200)
        {
            this.setState({
                archives : [],
                loading : false,
            })
            this.setState({archives : archives.data, loading : true}) 
        }
    }


    render(){
        var labels = [], data = [], rows = []

        var archives =
        this.state.archives.map((year) => {
            var note = 0, max = 0, students = 0, studentcont = year.students.length;
            year.students.map(studen =>{
                note += studen.studentsuccess > 0 ? studen.studentsuccess : 0;
                max += studen.studentmax > 0 ? studen.studentmax : 0;
                students = (note > 0 && max > 0) ? (note * 100) / max : 0;
            })
            labels.push(year.year)
            data.push(students > 0 ? students.toFixed(1) : 0)
            return(
                <div  key={year.id} className="overflow-hidden">
                    <li className="widget-todo-item list-group-item m-2 border border-secondary rounded-3 shadow mb-2">
                        <div
                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                            <div className="widget-todo-title-area d-flex align-items-center">
                                <FeatherIcon icon="package" className='cursor-move text-primary'></FeatherIcon> 
                                 <label htmlFor={year.id}><span className="widget-todo-title ml-50 fw-bolder" ><Link to={"/archives/student/"+year.id} className="text-dark text-decoration-none"> {year.year} <smal className="size-10 text-primary">({studentcont} élèves)</smal></Link></span></label>
                            </div>
                            <div className="widget-todo-item-action d-flex align-items-center">
                                <b><FeatherIcon icon="book-open" size="18" className='cursor-move'></FeatherIcon></b>
                            </div>
                        </div>
                    </li>
                    
                </div>
            )
        })
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | archived data stat</title>
                </Helmet>
            <div className=" pt-3 pb-2 mb-3 border-bottom">
                <h6 className="h5 font-monospace">Archive scolaire statistique</h6>
            </div>

            <div className=" mb-4 overflow-auto">
                <div className="">
                    <ul className="widget-todo-list-wrapper p-0">
                        {this.state.loading  ? archives : <Loading/>} 
                    </ul>
                </div>
            </div>

            <div className="mt-4">
                <div className="h6 text-center font-monospace">Statistique annuelle des resultats archivés</div>
                {
                    labels.length > 0 && (
                        <ReportLine
                            labels={labels}
                            data={data}
                            title={
                                "Statistique des resultats par classe " 
                            }
                        />
                       
                    )
                }
                
            </div>

        </div>
        )
    }
}
export default Archive;