import React, {Component} from "react";
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'
import Schedule from "./st/schedules/Schedule";
import axios from "axios";
Chart.register(...registerables)

class Dashboard extends Component{

    state = {
        
        labels :[],
        data : []
      }

      componentDidMount(){
        this.refreshToken();
        this.getStudents();
      }

      async refreshToken () {
        try{
            var token = await axios.get('token');
            if(token.status === 200)
            {
                var user = (token.data.data);
                localStorage.setItem("user", JSON.stringify(user));
            }
            else{
                axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
                window.localStorage.clear();
                window.location.replace(process.env.REACT_APP_EVIVESOMA_STAT +`/login`)
            }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
            window.location.replace(process.env.REACT_APP_EVIVESOMA_STAT +`/login`)
        }
    }

      async getStudents(){
        var students = await axios.get('students/limit');

        if(students.status === 200)
        {
            var labels = [], data = [];
            students.data.map((student) => {
                labels.push(student.matricule + " " + student.user.last_name);
                data.push(student.studentmax > 0 ? ((student.studentsuccess * 100 ) / student.studentmax).toFixed(1) : 0);
            })

            this.setState({
                labels : labels,
                data : data
            })
        }
      }

      handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }

    render(){
        
        var data = {
            labels: this.state.labels,
            datasets: [
            {
                label: 'points',
                backgroundColor: '#045fc0',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: this.state.data
            }
            ],
        }
        return(
            <div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h5 className="h5 font-monospace">Dashboard</h5>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <button type="button" className="btn btn-sm btn-outline-secondary dropdown-toggle">
                            <span data-feather="calendar"></span>
                            Année encours
                        </button>
                    </div>
                </div>
                <div className={ this.handleCheck("Profil admin") == true ? "d-10" : "d-none"}>
                {
                    data.labels.length > 0 && (
                        <Bar
                            data={data}
                            options={{
                            plugins: {
                                title: {
                                    display: true,
                                    text: "12 premiers élèves"
                                },
                                legend: {
                                    display: true,
                                    position: "bottom"
                                }
                            }
                            }}
                        />
                    )
                }
                </div>

            <Schedule />
            </div>
        )
    }
}
export default Dashboard;