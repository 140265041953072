import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   LessonForm extends Component{

    state = {
        id : null,
        scheduleId : "",
        reason_evaluation : null,
        max : null,
        error : "",
        
        isModal : false,
        title : null,

        schedules : [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    async getSchedules () {
        var id = this.props.sectionclasse ? this.props.sectionclasse.id : "--";
        var request = await axios.get("schedules/sectionclasse/" + id);
        if(request.status == 200)
        {
            this.setState({
                schedules : request.data
            })
        }
    }

    storeEvaluation = () =>{
        var data = {
                scheduleId : this.state.scheduleId,
                reason_evaluation : this.state.reason_evaluation,
                max : this.state.max > 0 ? this.state.max : null,
                operation : this.state.id ? "Editer" : "Ajouter",
            }
            
        var request = this.state.id ? axios.put('evaluations/'+this.state.id, data) : axios.post('evaluations', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.error
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    
    edit=()=>  {
        this.form()
        this.getSchedules()
    }

    form=()=>{ 
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            scheduleId: this.props.formData ? this.props.formData.scheduleId  : "",
            reason_evaluation: this.props.formData ? this.props.formData.reason_evaluation : "",
            max: this.props.formData ? this.props.formData.max : null,
            title : this.props.formData ? "Modifier la planification de l'evaluation" : "Planifier une evaluation",
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }
    
    render(){
        var user = JSON.parse(localStorage.getItem("user"))
        var schedules = [], date_start = "", count = [];
        this.state.schedules.filter(examen => examen.periodeId != 3 && examen.periodeId != 6 && examen.periodeId != 7 ).map((schedule) =>{
            date_start = "";
            if(schedule.time_start)
                date_start = date_start + " " + schedule.time_start +" - "
            if(schedule.time_end)
                date_start = date_start + " " + schedule.time_end
             
               
            if(this.state.scheduleId || this.props.sectionclasse && this.props.sectionclasse.id == schedule.sectionclasselesson.sectionclass.id)
            {
                if(schedule.sectionclasselesson.staff?.user?.id == user?.id || this.handleCheck("Profil admin") == true)
                { 
                    if(count.filter(item => item.periode  == schedule.periodeId && item.lesson == schedule.sectionclasselesson.lesson.id ).length == 0)
                    {
                        count.push({
                            lesson : schedule.sectionclasselesson.lesson.id,
                            periode : schedule.periodeId
                        })
                        schedules.push({ value : schedule.id, label : schedule.sectionclasselesson.lesson.entitled_lesson + " #"+ schedule.periodeId+ "P# " + date_start }) // +" " +schedule.time_start +" "+ schedule.time_end
                    }
                }
            }
        })
 
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                
                    <Modal.Title className="h6 font-monospace">{ this.state.title} { this.props.sectionclasse ? this.props.sectionclasse.class.short_entitled_class + " " + this.props.sectionclasse.title    : "" }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">

                    <div className="text-center"> <span className="text-danger text-small">{ this.state.error }</span></div>
                    <div className="form-group col-md-12 mt-2">
                        <label className="font-weight-bolder">Horaire de l'evaluation </label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    schedules.filter(option => option.value == this.state.scheduleId)
                                }
                                defaultValue={this.state.scheduleId}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            scheduleId : select.value
                                        }); 
                                    }
                                }
                                options={schedules.sort((a, b) => (a.label > b.label ? 1 : -1))}
                            />
                        </div>
                       
                    </div>

                    <div className="col-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Motif d'évaluation</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder="Motif d'évaluation"  onChange={this.handleInput} name="reason_evaluation"  value={this.state.reason_evaluation} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="trello"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Max</label>
                            <div className="position-relative mt-1">
                                <input type="number" className="form-control form-control-sm" placeholder="Max"  onChange={this.handleInput} name="max"  value={this.state.max} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="box"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    {
                        this.handleCheck("Ajouter evaluation") == true || this.handleCheck("Modifier evaluation") == true ? (
                        <button onClick={() => this.storeEvaluation()} className="btn btn-sm btn-outline-primary  p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                        ) : ""
                    }
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default LessonForm;