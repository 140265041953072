import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Loading from "../pages/Loading";

class Sidebar extends Component{

  state = {
    countData : 0,
    modal : false,
    isModal : false,
    table : []
  }

  componentDidMount(){
    this.getData()
  }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

  async getData(){
    var data = await axios.get('datamigrates');
    if(data.status == 200)
    {
      this.setState({
        countData : data.data.length,
      })
    }
  }

  refresh = () => {
    this.setState({
      modal : true,
      isModal : false
    })
    axios.get('datamigrates/pull').then((response) => {
      console.log(response)
        this.setState({
          modal : true,
          isModal : true
        })
    }).catch((error) => {
      console.log(error)
    })
    this.refreshsend()
  }

  refreshsend = () => {
    this.setState({
      modal : true,
      isModal : false
    })
    axios.get('datamigrates/push').then((response) => {
      
        this.setState({
          modal : true,
          isModal : true
        })
    }).catch((error) => {
      console.log(error)
      // this.refresh()
    })
  }

  modal = () =>{
    this.setState({
      modal : false,
      isModal : false
    })
  }

  logout = (e) => {
    axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
    window.localStorage.clear();
    window.location.replace(process.env.REACT_APP_EVIVESOMA_STAT)
}

change = (val) => {
  this.setState({
    table : []
  })
  var table = [];
  table.push(val)
  this.setState({
    table : table
  })
  this.getData()
}

    render(){

        return(

          <div className="col-auto col-md-3 col-xl-2 bg-white p-0 border border-top-0">
                <div className="sticky-top d-flex flex-column text-white min-vh-100 pt-0 " data-simplebar>
                    <a href="/" className="text-white text-decoration-none shadow text-lef font-weight-bold"  style={{ backgroundColor : "rgb(0, 108, 189)", fontWeight : 700}}>
                        <span className="fs-4 d-sm-inline"><img src="/logo192.png" alt="logo evive" className="" height={39+"px"}  /></span>
                        <span className="ms-2 fs-6 d-none d-sm-inline">{ process.env.REACT_APP_NAME }</span>
                    </a>
                    
                    <ul className="nav nav-pills flex-column mb-sm-auto ms-2 mt-2"   role="tablist" id="tab-menu">
                    {
                      this.handleCheck("Afficher tableau de bord") == true && (
                        <li className="nav-item" data-toggle="tooltip" data-placement="right" title="Dashboard" data-trigger="hover">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "schoolfees").map((val) => { return "active" })} onClick={() => this.change("schoolfees")}   aria-current="page" to={"/"}>
                            <FeatherIcon icon="activity"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Statistique</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher cours") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "sectionclasselessons").map((val) => { return "active" })} onClick={() => this.change("sectionclasselessons")}  aria-current="page" to={"/sectionclasselessons"}>
                            <FeatherIcon icon="framer"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Cours</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher horaire") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "schedules").map((val) => { return "active" })} onClick={() => this.change("schedules")}  aria-current="page" to={"/schedules"}>
                            <FeatherIcon icon="layout"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Horaire</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher evaluation") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "evaluations").map((val) => { return "active" })} onClick={() => this.change("evaluations")}  title={"Evaluation"} aria-current="page" to={"/evaluations"}>
                            <FeatherIcon icon="box"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Evaluation</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher evaluation") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "examens").map((val) => { return "active" })} onClick={() => this.change("examens")}  aria-current="page" to={"/examens"}>
                            <FeatherIcon icon="framer"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Examen</span>
                          </Link>
                        </li>
                      )
                    }
                    {
                      this.handleCheck("Afficher evaluation") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "drafts").map((val) => { return "active" })} onClick={() => this.change("drafts")}  aria-current="page" to={"/drafts"}>
                            <FeatherIcon icon="command"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">2ieme sessions</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher evaluation") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "reportcardfiles").map((val) => { return "active" })} onClick={() => this.change("reportcardfiles")}  aria-current="page" to={"/reportcardfiles"}>
                            <FeatherIcon icon="book"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Fiche des points</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher bulletin") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "reportcards").map((val) => { return "active" })} onClick={() => this.change("reportcards")}  aria-current="page" to={"/reportcards"}>
                            <FeatherIcon icon="codesandbox"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Bulletin</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher bulletin") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "reportcardfails").map((val) => { return "active" })} onClick={() => this.change("reportcardfails")}  aria-current="page" to={"/reportcardfails"}>
                            <FeatherIcon icon="book-open"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Echecs</span>
                          </Link>
                        </li>
                      )
                    }

                    
                    {
                      this.handleCheck("Afficher discipline") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "disciplines").map((val) => { return "active" })} onClick={() => this.change("disciplines")} aria-current="page" to={"/disciplines"}>
                            <FeatherIcon icon="folder" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Discipline</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Profil admin") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "presence").map((val) => { return "active" })} onClick={() => this.change("presences")} aria-current="page" to={"/presences"}>
                            <FeatherIcon icon="folder" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Présence</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Ajouter discipline") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "sms").map((val) => { return "active" })} onClick={() => this.change("sms")} aria-current="page" to={"/sms"}>
                            <FeatherIcon icon="mail" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">SMS</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher rapport") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "repports").map((val) => { return "active" })} onClick={() => this.change("repports")}  aria-current="page" to={"/repports"}>
                            <FeatherIcon icon="server"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Rapports</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher archive") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "archives").map((val) => { return "active" })} onClick={() => this.change("archives")}  aria-current="page" to={"/archives"}>
                            <FeatherIcon icon="share-2"  size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Archive</span>
                          </Link>
                        </li>
                      )
                    }
                      {/* {
                      this.handleCheck("Afficher tableau de bord") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "}  onClick={() => this.refresh()}>
                            <FeatherIcon icon="database" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Refresh ({this.state.countData > 0 ? this.state.countData : "0"})</span>
                          </Link>
                        </li>
                      )} */}

                        <li className="nav-item">
                          <Link className="nav-link p-2" onClick={() => this.logout()} aria-current="page" to={"/#"}>
                            <FeatherIcon icon="lock" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Déconnexion</span>
                          </Link>
                        </li>

                    </ul>
                </div>

                <Modal
                  show={this.state.modal} 
                  onHide={this.state.modal}
                  backdrop={"static"}>
                  <Modal.Header>
                      <Modal.Title className="h6 font-monospace">Actualisation des données</Modal.Title>
                  </Modal.Header>
                    <Modal.Body>
                        <div className="text-center font-monospace">{ this.state.isModal ? "" : <div><Loading /></div>}</div>
                      </Modal.Body>
                    <Modal.Footer>
                      {
                        this.state.isModal && (
                          <button onClick={() => this.modal() } className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        )
                      }
                    </Modal.Footer>
                </Modal>
            </div>
            
        )
    }
}
export default Sidebar;