import React, {Component} from "react";
import ReactToPrint from "react-to-print";
import { MDBDataTable } from 'mdbreact';
import FeatherIcon from 'feather-icons-react';
import Headerpdf from "./users/Headerpdf";

class Listprint extends Component {
    state = {
        columns : [],
        rows : [],
    }

    componentDidMount(){
        this.setState({
            columns : this.props.columns,
            rows : this.props.rows,
        })
        
    }
    
    render(){
        var establishment = JSON.parse(localStorage.getItem('establishment'))
        return(
            <div id="containerpdf">
                <div className="text-end mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <ReactToPrint
                            trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                            content={() =>  this.componentRef}
                        />
                    </div>
                </div>
                <div className="m-2" ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                    
                    <div className="text-center text-monospace h6 userIdentity">{ this.props.title }</div>
                    <div className="row">
                        <div className="table-responsive">
                            <MDBDataTable
                                paging={true}
                                striped
                                bordered
                                small
                                data={this.state}
                            />
                        </div>
                    </div>
                     


                </div>
            </div>
        )
    }
}
export default Listprint;