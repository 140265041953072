import axios from "axios";
import React, {Component} from "react";
import Loading from "../../Loading";
import StudentreportcardCTEB1 from "./StudentreportcardCTEB1";
import StudentreportcardCTEB2 from "./StudentreportcardCTEB2";
import StudentreportcardHUMANITE from "./StudentreportcardHUMANITE";
import StudentreportcardDM from "./StudentreportcardDM";

class   Studentreportcard extends Component{

    state = {
        student : {},
        loading : false
    }

    componentDidMount(){
        this.student();
    }

    async student(){

        var student = await axios.get(`student/` + this.props.match.params.student);
         if(student.status === 200)
        {
            this.setState({student : student.data, loading : true})
        }
    }

    render(){
        var reportcard = "";
        // console.log(this.state.student?.sectionclass?.reportcardcategoryId, '=============');
        if(this.state.loading){
            if(this.state.student?.sectionclass?.reportcardcategoryId == 1 || this.state.student?.sectionclass?.reportcardcategoryId == 2)
                reportcard = <StudentreportcardHUMANITE studentId={this.props.match.params.student} />
            else if(this.state.student?.sectionclass?.reportcardcategoryId == 3)
                reportcard = <StudentreportcardCTEB1 studentId={this.props.match.params.student} />
            else if(this.state.student?.sectionclass?.reportcardcategoryId == 4)
                reportcard = <StudentreportcardCTEB2 studentId={this.props.match.params.student} />
            else 
                reportcard = <StudentreportcardHUMANITE studentId={this.props.match.params.student} />
        }else{
            reportcard = <Loading />
        }
        return(
            <div>
                {reportcard}
            </div>
        )
    }
}
export default Studentreportcard;