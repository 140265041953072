import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Headerpdf from "../../users/Headerpdf";

class DataRecordcard extends Component{

    render(){
        
        var  max=0, note=0;
        var list = 
        this.props.section.sectionclasses.map((sectionclasse) => {
            max=0;
            note=0
            return(
                <li className="widget-todo-item list-group-item mt-4 shadow border rounded-3">
                <div
                    className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50 overflow-hidden">
                    <div className="widget-todo-title-area d-flex align-items-center">
                        <FeatherIcon icon="codesandbox" className='cursor-move'></FeatherIcon>
                        
                        <label htmlFor={this.props.section.id}><span className="widget-todo-title ml-50 fw-bold">{sectionclasse.class.short_entitled_class} <span className="text-primary">{sectionclasse.title}</span>  {this.props.section.short_entitled_section} </span></label>
                    </div>
                    <div className="widget-todo-item-action d-flex align-items-center">
                        <div className="text-end mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <ReactToPrint
                                    trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                                    content={() =>  this.componentRef}
                                />
                            </div>
                        </div>
                        {this.props.section.short_entitled_section} <FeatherIcon icon="list" size="16" className='cursor-move text-dark'></FeatherIcon>
                    </div>
                </div>
                <div>
                <div className="table-responsive m-2 " ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                    <div className="text-center h6">
                        <label htmlFor={this.props.section.id}><span className="widget-todo-title ml-50 fw-bold">Resultat {sectionclasse.class.short_entitled_class} <span className="text-primary">{sectionclasse.title}</span>  {this.props.section.entitled_section}</span></label>
                    </div>
                    <table className="table table-striped table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Matr</th>
                                <th scope="col">Nom et post nom</th>
                                <th scope="col">Pourcent</th>
                                <th scope="col">Année</th>
                            </tr>
                        </thead>
                        <tbody>
                             
                            {
                                sectionclasse.students.filter(student => student.status == 1 || student.status == 2).sort((a, b) => ( ( (a.studentsuccess * 100 ) / a.studentmax) < ((b.studentsuccess * 100 ) / b.studentmax) ? 1 : -1 ) ).map((student) => {
                                    note += student.studentsuccess
                                    max += student.studentmax
                                    var last_name = student.user ? student.user.last_name : "";
                                    var first_name = student.user ? student.user.first_name : "";
                                    var family_name = student.user ? student.user.family_name : "";
                                    return(
                                        <tr key={student.id}>
                                            <td>
                                                <Link to={"/students/"+student.id} className="text-primary text-decoration-none">
                                                    { student.matricule }
                                                </Link>
                                             </td>
                                            <td>
                                                <img src={student.user && (student.user.avatar)} className="rounded img-fluid" title={(first_name +" "+ last_name)} width={student.user && (student.user.avatar && ("30"))} />
                                                 {student.user && (last_name +" "+ family_name +" "+ first_name)}
                                            </td>
                                            <td>{ student.studentmax > 0 ? (((student.studentsuccess * 100 ) / student.studentmax).toFixed(1)) + "%" : ""}</td>
                                            <td>{ student.year.year}</td>
                                        </tr>
                                    )
                                })
                            }
                            <tr>
                                <th></th>
                                <th>Moyenne</th>
                                <th>{ max > 0 ? (((note * 100 ) / max).toFixed(1) ) + "%" : "" }</th>
                                <th></th>
                            </tr>
                        </tbody>
                    </table>
                </div>
    
                </div>
            </li>
            )
        })

        return( list )
    }
}
export default DataRecordcard