import axios from "axios";
import React, {Component} from "react";
import ReactToPrint from "react-to-print";
import FeatherIcon from 'feather-icons-react';

class   StudentreportcardHUMANITE extends Component{

    state = {
        isModal : false,
        formData : null,
        reportcard : null,
        sectionclasselessons : [],
        reportcards : [],
        max : [],
    }

    componentDidMount(){
        this.getReportcard();
    }

    async getReportcard(){
        var reportcard = await axios.get(`students/${this.props.studentId}`);
        
        if(reportcard.status === 200)
        {
            this.setState({reportcard : reportcard.data}) 
            if(this.state.reportcard){
                this.sectionclasselessons()
                this.reportcards();
            }
        }
    }

    async sectionclasselessons(){
        var sectionclasselessons = await axios.get('sectionclasselessons/distinct/' + this.state.reportcard.sectionclass.id);
        
        if(sectionclasselessons.status === 200)
        {
            this.setState({sectionclasselessons : sectionclasselessons.data}) 
        }
    }

    async reportcards(){
        var reportcards = await axios.get('reportcards/sectionclass/' + this.state.reportcard.sectionclass.id);
        if(reportcards.status === 200)
        {
            this.setState({reportcards : reportcards.data}) 
        }
    }


    showModal = (reportcard = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : reportcard,
        })
        this.getReportcard()
    }

    delete = (reportcard) =>{
        axios.delete("reportcards/" + reportcard).then((response) => {
            this.getReportcard()
        }).catch((error)=>{})
    }

    render(){
        var itemNote = [], profile = JSON.parse(localStorage.getItem('profile'));

        this.state.sectionclasselessons.map((sectionclasselesson) =>{
            if(itemNote.filter(note => note == sectionclasselesson.max_evaluation).length == 0)
                itemNote.push(sectionclasselesson.max_evaluation)
        })
        var itemData = [],
            newtable = [], 
            somme1 = 0,
            somme2 = 0,

            periode1 =0,
            periode2 =0,
            periode3 =0,
            periode4 =0,
            periode5 =0,
            periode6 =0,
            periode7 =0,
            periode8 =0,
            
            periodeT1 =0,
            periodeT2 =0,
            periodeT3 =0,
            periodeT4 =0,
            periodeT5 =0,
            periodeT6 =0,
            periodeT7 =0,
            periodeT8 =0,

            periodeP1 = 0,
            periodeP2 = 0,
            periodeP3 = 0,
            periodeP4 = 0;

        return(
            <div>
                <div className="text-end">
                    <button type="button" className="btn d-none btn-sm btn-outline-primary me-2" onClick={() => this.setState({ btn : !this.state.btn })}><FeatherIcon icon="send" ></FeatherIcon> Changer bulletin </button>
                    <ReactToPrint
                        trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                        content={() =>  this.componentRef}
                    />
                </div>
                
                    <div className=""  ref={el => (this.componentRef = el)}>
                        <div className="d-flex bd-highlight mb-0 border border-dark">
                            <div className="p-0 bd-highlight">
                                <img src={"/assets/drc.png"} alt=""  className="rounded img-fluid" style={{ width:80+"px" }} />
                            </div>
                            <div className="p-0 flex-grow-1 bd-highlight">
                                <div className="fw-bolder text-dark text-center">
                                    REPUBLIQUE DEMOCRATIQUE DU CONGO <br />
                                    MINISTERE DE L'ENSEIGNEMENT PRIMAIRE, SECONDAIRE <br />ET TECHNIQUE
                                </div>
                            </div>
                            <div className="p-0 bd-highlight">
                                <img src={ this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.user.avatar : "/assets/drc.png" : "/assets/drc.png" } alt=""  className="rounded img-fluid" style={{ width:60+"px" }} />
                            </div>
                        </div>
                        
                        <div className="border border-dark d-flex justify-content-between">
                            <span className="fw-bolder d-inlin  border m-0 p-0">N°ID </span>  
                            <table className="table d-inline  pb-0 m-0">
                                <tr>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td>
                                </tr>
                            </table>  
                        </div>
                        <div className="border border-dark">
                            <span className="fw-bolder d-inline  ml-3   m-0 p-0">Province : </span>   { this.state.reportcard?.establishment?.region}
                        </div>

                        <div className="border border-dark">
                            <div className="row p-0 m-0 ">
                                <div className="col-6 border-end ">
                                    <div className=" overflow-hidden">
                                        <div className="">VILLE : { this.state.reportcard?.establishment?.city}</div>
                                        <div className="">COMMUNE/TER : { this.state.reportcard?.establishment?.municipality}   </div>
                                        <div className=" overflow-hidden">ECOLE : { this.state.reportcard?.establishment?.name} </div>
                                        <div className=" d-flex justify-content-between p-0 m-0">
                                            <span className=" d-inline mt-2">CODE: </span>  
                                            <table className="table d-inline mt-2">
                                                <tr>
                                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}> .</td> 
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div> 
                                <div className="col-6 border-start ">
                                    <div className="p-1">
                                        <div className=" d-flex justify-content-between overflow-hidden">

                                            <div>ELEVE :  { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.user.last_name : "" : "" }  { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.user.family_name : "" : "" }  { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.user.first_name : "" : "" } </div> 
                                            <div className="me-2">SEXE : { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.user.genre : "" : "" }</div> 
                                        </div>
                                        <div className="d-flex justify-content-between overflow-hidden">
                                            <div>NE(E) A : { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.user.adress : "" : "" } </div> 
                                            <div>LE :  { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.user.datebirth ? this.state.reportcard.user.datebirth.slice(0, 10) : "...../...../....." : "...../...../....." : "...../...../....." } </div> 
                                        </div>
                                        <div className="">CLASSE : { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.sectionclass.class.short_entitled_class : "" : "" } { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.sectionclass.title : "" : "" }</div>
                                        <div className="  d-flex justify-content-between p-0">
                                            <span className=" d-inline mt-2 ml-3 mb-1">N°PERM. </span>  
                                            <table className="table d-inline mt-1">
                                                <tr>
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                    <td className="border border-dark text-white" width={"40px"} height={""}>. </td> 
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>   
                            </div>
                        </div>

                        <div className="border border-dark">
                            <div className="d-flex justify-content-between bg-light">
                                <div className="ms-4 ps-4 fw-bolder">BULLETIN DE LA { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.sectionclass.class.short_entitled_class : "" : "" } ANNEE { this.state.reportcard ? this.state.reportcard.user ? this.state.reportcard.sectionclass.section.entitled_section : "" : "" } </div> 
                                <div className="me-4 fw-bolder">ANNEE SCOLAIRE { this.state.reportcard ? this.state.reportcard.year ? this.state.reportcard.year.year : "" : "" } </div> 
                            </div>
                        </div>

                    <div className="table-responsive border border-dark m-0 p-0">
                        <table className="table table-striped table-sm m-0 p-0">
                            <tr>
                                <td rowSpan={3} width="20%" className="border border-dark"><div className="text-center p-0 m-0 fw-bolder">BRANCHES</div></td>
                                <td width="19%" colSpan={4} className="border border-dark"><div className="text-center p-0 m-0 fw-bolder">PREMIER SEMESTRE</div></td>
                                <td width="19%" colSpan={4} className="border border-dark"><div className="text-center p-0 m-0 fw-bolder">SECOND SEMESTRE</div></td>
                                <td rowSpan={3} width="3%" className="border border-dark"><div className="text-center p-0 m-0 fw-bolder">TOTAL GENER.</div></td>
                                {/* <td rowSpan={3} width="1%" className="border border-dark bg-light"><div className="text-center p-0 m-0 fw-bolder"></div></td> */}
                                <td width="19%"  colSpan={2} className="border border-dark"><div className="text-center p-0 m-0 fw-bolder">EXAMEN DE REPECHAGE</div></td>
                            </tr>
                            <tr>
                                <td colSpan={2} className="border border-dark"><span className="text-center ">TRAV.JOUR</span></td>
                                <td rowSpan={2} className="border border-dark"><span className="text-center ">EXAM.</span></td>
                                <td rowSpan={2} className="border border-dark"><span className="text-center ">TOT</span></td>

                                <td colSpan={2} className="border border-dark"><span className="text-center ">TRAV.JOUR</span></td>
                                <td rowSpan={2} className="border border-dark"><span className="text-center ">EXAM.</span></td>
                                <td rowSpan={2} className="border border-dark"><span className="text-center ">TOT</span></td>
                                
                                
                                <td rowSpan={2} className="border border-dark"><span className="text-center ">%</span></td>
                                <td rowSpan={2} className="border border-dark"><span className="text-center ">Signature du Prof.</span></td>
                            </tr>

                            <tr>
                                <td className="border border-dark"><span className="text-center ">1eP</span></td>
                                <td className="border border-dark"><span className="text-center ">2eP</span></td>

                                <td className="border border-dark"><span className="text-center ">3eP</span></td>
                                <td className="border border-dark"><span className="text-center ">4eP</span></td>
                            </tr>
 
                            {
                                this.state.reportcard && (
                                    this.state.reportcard.sectionclass && (
                                        itemNote.map(note => {
                                            return(
                                                this.state.sectionclasselessons.filter(dom => dom.max_evaluation == note).sort((a, b) => (a.indice > b.indice) ? 1 : -1).map(((sectionclasselesson, index) => {
                                                    return(
                                                        <>
                                                            <tr key={sectionclasselesson.id} className={index != 0 ? "d-none" : ""}>
                                                                <th className="border border-dark ">MAXIMA</th>
                                                                <th className="border border-dark">{ sectionclasselesson.max_evaluation}</th>
                                                                <th className="border border-dark">{ sectionclasselesson.max_evaluation}</th>
                                                                <th className="border border-dark">{ sectionclasselesson.max_examen}</th>
                                                                <th className="border border-dark">{ parseInt(sectionclasselesson.max_examen) +  parseInt(sectionclasselesson.max_evaluation) +  parseInt(sectionclasselesson.max_evaluation) }</th>
                                                                <th className="border border-dark">{ sectionclasselesson.max_evaluation}</th>
                                                                <th className="border border-dark">{ sectionclasselesson.max_evaluation}</th>
                                                                <th className="border border-dark">{ sectionclasselesson.max_examen}</th>
                                                                <th className="border border-dark">{  parseInt(sectionclasselesson.max_examen) +  parseInt(sectionclasselesson.max_evaluation) +  parseInt(sectionclasselesson.max_evaluation)}</th>
                                                                <th className="border border-dark">{  parseInt(sectionclasselesson.max_examen) +  parseInt(sectionclasselesson.max_evaluation) +  parseInt(sectionclasselesson.max_evaluation) +  parseInt(sectionclasselesson.max_examen) +  parseInt(sectionclasselesson.max_evaluation) +  parseInt(sectionclasselesson.max_evaluation)}</th>
                                                                <th className="border border-dark  bg-light "></th>
                                                                <th className="border border-dark  bg-light"></th>
                                                                {/* <th className="border border-dark  bg-light"></th> */}
                                                            </tr>

                                                            {/* {
                                                                this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id ).map((reportcard) =>{
                                                                    return( */}
                                                                            <tr>
                                                                                <td className="border border-dark">{ sectionclasselesson.lesson.entitled_lesson}</td>
                                                                                <td className="border border-dark">
                                                                                    {
                                                                                        this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id && data.periodeId == 1 ).map((reportcard) =>{
                                                                                            periodeT1 = periodeT1 + sectionclasselesson.max_evaluation;
                                                                                            periode1 = reportcard.note != null ? periode1 + reportcard.note : periode1;
                                                                                            somme1 =  reportcard.note != null ? somme1 + reportcard.note : somme1;
                                                                                            periodeP1 =  reportcard.note != null ? periodeP1 + sectionclasselesson.max_evaluation : periodeP1;
                                                                                            periodeP4 = reportcard.note != null ? periodeP4 + sectionclasselesson.max_evaluation : periodeP4;
                                                                                            return (<span className={ (reportcard.note < (sectionclasselesson.max_evaluation / 2)) ? "text-danger" : "text-primary"}>{  reportcard.note?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, })  }</span>)
                                                                                        })
                                                                                    }
                                                                                </td>
                                                                                <td className="border border-dark">
                                                                                    {
                                                                                        this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id && data.periodeId == 2 ).map((reportcard) =>{
                                                                                            periodeT2 = periodeT2 + sectionclasselesson.max_evaluation;
                                                                                            periode2 = reportcard.note != null ? periode2 + reportcard.note : periode2;
                                                                                            somme1 =  reportcard.note != null ? somme1 + reportcard.note : somme1;
                                                                                            periodeP2 =  reportcard.note != null ? periodeP2 + sectionclasselesson.max_evaluation : periodeP2;
                                                                                            periodeP4 = reportcard.note != null ? periodeP4 + sectionclasselesson.max_evaluation : periodeP4;
                                                                                            return (<span className={ (reportcard.note < (sectionclasselesson.max_evaluation / 2)) ? "text-danger" : "text-primary"}> { reportcard.note?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, })   } </span>)
                                                                                        })
                                                                                    }
                                                                                </td>

                                                                                <td className="border border-dark">
                                                                                    {
                                                                                        this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id && data.periodeId == 3  ).map((reportcard) =>{
                                                                                            periodeT3 = periodeT3 + sectionclasselesson.max_examen;
                                                                                            periode3 = reportcard.note != null ? periode3 + reportcard.note : periode3;
                                                                                            somme1 =  reportcard.note != null ? somme1 + reportcard.note : somme1;
                                                                                            periodeP3 =  reportcard.note != null ? periodeP3 + sectionclasselesson.max_examen : periodeP3;
                                                                                            periodeP4 = reportcard.note != null ? periodeP4 + sectionclasselesson.max_examen : periodeP4;
                                                                                            return (<span className={ (reportcard.note < (sectionclasselesson.max_evaluation)) ? "text-danger" : "text-primary"}> { reportcard.note?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, })   } </span>)
                                                                                        })
                                                                                    }
                                                                                </td>
                                                                                <td className="border border-dark">
                                                                                    {
                                                                                        this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id && data.periodeId == 3).map((reportcard) =>{
                                                                                            periodeT4 = periodeT1 + periodeT2 +periodeT3 ;
                                                                                            periode4 = periode1 + periode2 + periode3 ;
                                                                                            return(
                                                                                                <span className={ (somme1 < periodeT4 / 2 ) ? "text-danger" : "text-primary"}> { reportcard.note != null ? somme1?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : ""  } </span>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </td>

                                                                                <td className="border border-dark">
                                                                                    {
                                                                                        this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id && data.periodeId == 4 ).map((reportcard) =>{
                                                                                            periodeT5 = periodeT5 + sectionclasselesson.max_evaluation;
                                                                                            periode5 = reportcard.note != null ? periode5 + reportcard.note : periode5;
                                                                                            somme2 =  reportcard.note != null ? somme2 + reportcard.note : somme2;
                                                                                            return (<span className={ (reportcard.note < (sectionclasselesson.max_evaluation / 2)) ? "text-danger" : "text-primary"}> { reportcard.note?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, })   } </span>)
                                                                                        })
                                                                                    }
                                                                                </td>
                                                                                <td className="border border-dark">
                                                                                    {
                                                                                        this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id && data.periodeId == 5 ).map((reportcard) =>{
                                                                                            periodeT6 = periodeT6 + sectionclasselesson.max_evaluation;
                                                                                            periode6 = reportcard.note != null ? periode6 + reportcard.note : periode6;
                                                                                            somme2 =  reportcard.note != null ? somme2 + reportcard.note : somme2;
                                                                                            return (<span className={ (reportcard.note < (sectionclasselesson.max_evaluation / 2)) ? "text-danger" : "text-primary"}> { reportcard.note?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, })   } </span>)
                                                                                        })
                                                                                    }
                                                                                </td>
                                                                                <td className="border border-dark">
                                                                                    {
                                                                                        this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id && data.periodeId == 6 ).map((reportcard) =>{
                                                                                            periodeT7 = periodeT7 + sectionclasselesson.max_examen;
                                                                                            periode7 = reportcard.note != null ? periode7 + reportcard.note : periode7;
                                                                                            somme2 =  reportcard.note != null ? somme2 + reportcard.note : somme2;
                                                                                            return (<span className={ (reportcard.note < (sectionclasselesson.max_evaluation / 2)) ? "text-danger" : "text-primary"}> { reportcard.note?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, })   } </span>)
                                                                                        })
                                                                                    }
                                                                                </td>
                                                                                <td className="border border-dark ">
                                                                                    {
                                                                                        this.state.reportcard.reportcards.filter(data => data.sectionclasselessonId == sectionclasselesson.id && data.periodeId == 6).map((reportcard) =>{
                                                                                            periodeT8 = periodeT5 + periodeT6 + periodeT7 ;
                                                                                            periode8 = periode5 + periode6 + periode7;
                                                                                            return(
                                                                                                <span className={ (somme2 < periodeT8 / 2) ? "text-danger" : "text-primary"}> { reportcard.note != null ? somme2?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : ""  } </span> 
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </td>
                                                                                    
                                                                                <td className="border border-dark "></td>
                                                                                <td className="border border-dark   bg-light"></td>
                                                                                <td className="border border-dark "></td>
                                                                                {/* <td className="border border-dark "></td> */}
                                                                            </tr>
                                                                        {/* )
                                                                })
                                                            } */}
                                                        </>
                                                    )
                                                }))
                                            )
                                        })
                                    )
                                )
                            }
                            
                            
                                <tr key={"evive"}>
                                    <th className="border border-dark ">MAXIMA GENERAUX</th>
                                    <th className="border border-dark text-danger">{ periodeT1 > 0 ? periodeT1?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <th className="border border-dark text-danger">{ periodeT2 > 0 ? periodeT2?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <th className="border border-dark text-danger">{ periodeT3 > 0 ? periodeT3?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <th className="border border-dark text-danger">{ periodeT4 > 0 ? periodeT4?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <th className="border border-dark text-danger">{ periodeT5 > 0 ? periodeT5?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <th className="border border-dark text-danger">{ periodeT6 > 0 ? periodeT6?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <th className="border border-dark text-danger">{ periodeT7 > 0 ? periodeT7?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <th className="border border-dark text-danger">{ periodeT8 > 0 ? periodeT8?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <th className="border border-dark text-danger">{ periodeT8 > 0 ? (periodeT4 + periodeT8)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</th>
                                    <td className="border border-dark  bg-light"></td>
                                    <td className="border border-0"></td>
                                </tr>

                                <tr key={"eviveF"}>
                                    <td className="border border-dark ">TOTAUX</td>
                                    <td className="border border-dark ">{ periode1 > 0 ? periode1?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark ">{ periode2 > 0 ? periode2?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark ">{ periode3 > 0 ? periode3?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark ">{ periode4 > 0 ? periode4?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark ">{ periode5 > 0 ? periode5?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark ">{ periode6 > 0 ? periode6?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark ">{ periode7 > 0 ? periode7?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark ">{ periode8 > 0 ? periode8?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark ">{ periode8 > 0 ? (periode4 + periode8)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark  bg-light"></td>
                                    <td className="border border-0"></td>
                                </tr>

                                <tr key={"eviveV"}>
                                    <td className="border border-dark ">POURCENTAGE</td>
                                    <td className="border border-dark " title={ periode1 + "/" + periodeP1 }>{ periode1 > 0 ? ((periode1 * 100) / periodeP1)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark " title={ periode2 + "/" + periodeP2 }>{ periode2 > 0 ? ((periode2 * 100) / periodeP2)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark " title={ periode3 + "/" + periodeP3 }>{ periode3 > 0 ? ((periode3 * 100) / periodeP3)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }): "" }</td>
                                    <td className="border border-dark " title={ periode4 + "/" + periodeP4 }>{ periode4 > 0 ? ((periode4 * 100) / periodeP4)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark " title={ periode5 + "/" + periodeP1 }>{ periode5 > 0 ? ((periode5 * 100) / periodeP1)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark " title={ periode6 + "/" + periodeP2 }>{ periode6 > 0 ? ((periode6 * 100) / periodeP2)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark " title={ periode7 + "/" + periodeP3 }>{ periode7 > 0 ? ((periode7 * 100) / periodeP3)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark " title={ periode8 + "/" + periodeP4 }>{ periode8 > 0 ? ((periode8 * 100) / periodeP4)?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark " title={ (periode4 + periode8) + "/" + (periodeP4 + periodeP4) }>{ periode8 > 0 ?  (((periode4 + periode8) * 100) / (periodeP4 + periodeP4))?.toLocaleString(undefined, { minimumFractionDigits:0, maximumFractionDigits:1, }) : "" }</td>
                                    <td className="border border-dark bg-light "></td>
                                    <td className="border border-0"></td>
                                </tr>

                                <tr key={"eviveV"}>
                                    <td className="border border-dark ">PLACE/NBRE ELEVE</td>
                                    <td className="border border-dark " title={ periode1 + "/" + periodeP1 }>
                                        {
                                            this.state.reportcards.filter(data => data.periodeId == 1).sort((a, b) => ( ( (a.note * 100 ) / a.max_evaluation) < ((b.note * 100 ) / b.max_evaluation) ? 1 : -1 ) ).map((place, index) => {
                                                if(this.state.reportcard && periode1 > 0 && place.studentId == this.state.reportcard.id){
                                                    return(
                                                        <span style={{  fontSize : 9 + "px" }}>{index + 1 }{ index == 0 ? "e" : "ie" }/{ this.state.reportcards.filter(stud =>stud.periodeId == 1 && stud.student.status == 1 ||  stud.periodeId == 1 && stud.student.status == 2 ).map(item => item.studentId).filter((value, index, self) => self.indexOf(value) === index).length }</span>
                                                    )
                                                }
                                            })
                                        }
                                    </td>
                                    <td className="border border-dark " title={ periode2 + "/" + periodeP2 }>
                                        {
                                            this.state.reportcards.filter(data => data.periodeId == 2).sort((a, b) => ( ( (a.note * 100 ) / a.max_evaluation) < ((b.note * 100 ) / b.max_evaluation) ? 1 : -1 ) ).map((place, index) => {
                                                if(this.state.reportcard && periode2 > 0 && place.studentId == this.state.reportcard.id){
                                                    return(
                                                        <span style={{  fontSize : 9 + "px" }}>{index + 1 }{ index == 0 ? "e" : "ie" }/{ this.state.reportcards.filter(stud =>stud.periodeId == 2 && stud.student.status == 1 ||  stud.periodeId == 2 && stud.student.status == 2 ).map(item => item.studentId).filter((value, index, self) => self.indexOf(value) === index).length }</span>
                                                    )
                                                }
                                            })
                                        }
                                    </td>
                                    <td className="border border-dark " title={ periode3 + "/" + periodeP3 }>
                                        {
                                            this.state.reportcards.filter(data => data.periodeId == 3).sort((a, b) => ( ( (a.note * 100 ) / a.max_evaluation) < ((b.note * 100 ) / b.max_evaluation) ? 1 : -1 ) ).map((place, index) => {
                                                if(this.state.reportcard && periode3 > 0 && place.studentId == this.state.reportcard.id){
                                                    return(
                                                        <span style={{  fontSize : 9 + "px" }}>{index + 1 }{ index == 0 ? "e" : "ie" }/{ this.state.reportcards.filter(stud =>stud.periodeId == 3 && stud.student.status == 1 ||  stud.periodeId == 3 && stud.student.status == 2 ).map(item => item.studentId).filter((value, index, self) => self.indexOf(value) === index).length }</span>
                                                    )
                                                }
                                            })
                                        }
                                    </td>
                                    <td className="border border-dark "> </td>
                                    <td className="border border-dark " title={ periode4 + "/" + periodeP4 }>
                                        {
                                            this.state.reportcards.filter(data => data.periodeId == 4).sort((a, b) => ( ( (a.note * 100 ) / a.max_evaluation) < ((b.note * 100 ) / b.max_evaluation) ? 1 : -1 ) ).map((place, index) => {
                                                if(this.state.reportcard && periode3 > 0 && place.studentId == this.state.reportcard.id){
                                                    return(
                                                        <span style={{  fontSize : 9 + "px" }}>{index + 1 }{ index == 0 ? "e" : "ie" }/{ this.state.reportcards.filter(stud =>stud.periodeId == 4 && stud.student.status == 1 ||  stud.periodeId == 4 && stud.student.status == 2 ).map(item => item.studentId).filter((value, index, self) => self.indexOf(value) === index).length }</span>
                                                    )
                                                }
                                            })
                                        }
                                    </td>
                                    <td className="border border-dark " title={ periode5 + "/" + periodeP1 }>
                                        {
                                            this.state.reportcards.filter(data => data.periodeId == 5).sort((a, b) => ( ( (a.note * 100 ) / a.max_evaluation) < ((b.note * 100 ) / b.max_evaluation) ? 1 : -1 ) ).map((place, index) => {
                                                if(this.state.reportcard && periode5 > 0 && place.studentId == this.state.reportcard.id){
                                                    return(
                                                        <span style={{  fontSize : 9 + "px" }}>{index + 1 }{ index == 0 ? "e" : "ie" }/{ this.state.reportcards.filter(stud =>stud.periodeId == 5 && stud.student.status == 1 ||  stud.periodeId == 5 && stud.student.status == 2 ).map(item => item.studentId).filter((value, index, self) => self.indexOf(value) === index).length }</span>
                                                    )
                                                }
                                            })
                                        }
                                    </td>
                                    <td className="border border-dark " title={ periode6 + "/" + periodeP2 }>
                                        {
                                            this.state.reportcards.filter(data => data.periodeId == 6).sort((a, b) => ( ( (a.note * 100 ) / a.max_evaluation) < ((b.note * 100 ) / b.max_evaluation) ? 1 : -1 ) ).map((place, index) => {
                                                if(this.state.reportcard && periode5 > 0 && place.studentId == this.state.reportcard.id){
                                                    return(
                                                        <span style={{  fontSize : 9 + "px" }}>{index + 1 }{ index == 0 ? "e" : "ie" }/{ this.state.reportcards.filter(stud =>stud.periodeId == 6 && stud.student.status == 1 ||  stud.periodeId == 6 && stud.student.status == 2 ).map(item => item.studentId).filter((value, index, self) => self.indexOf(value) === index).length }</span>
                                                    )
                                                }
                                            })
                                        }
                                    </td>
                                    <td className="border border-dark"></td>
                                    <td className="border border-dark"></td>
                                    <td className="border border-dark"></td>
                                    <td className="border border-0"></td>
                                </tr>

                                <tr key={"eviveVe"}>
                                    <td className="border border-dark ">APPLICATION</td>
                                    <td className="border border-dark " > </td>
                                    <td className="border border-dark " > </td>
                                    <td className="border border-dark bg-light"> </td>
                                    <td className="border border-dark bg-light"></td>
                                    <td className="border border-dark "> </td>
                                    <td className="border border-dark "></td>
                                    <td className="border border-dark bg-light"></td>
                                    <td className="border border-dark bg-light"></td>
                                    <td className="border border-dark bg-light"></td>
                                    <td className="border border-dark bg-light "></td>
                                    <td className="border border-0"></td>
                                </tr>

                                <tr key={"eviveV"}>
                                    <td className="border border-dark ">CONDUITE</td>
                                    <td className="border border-dark " > </td>
                                    <td className="border border-dark " > </td>
                                    <td className="border border-dark bg-light"> </td>
                                    <td className="border border-dark bg-light"></td>
                                    <td className="border border-dark "> </td>
                                    <td className="border border-dark "></td>
                                    <td className="border border-dark bg-light"></td>
                                    <td className="border border-dark bg-light"></td>
                                    <td className="border border-dark bg-light"></td>
                                    <td className="border border-dark bg-light "></td>
                                    <td className="border border-0"></td>
                                </tr>
                        </table>

                        <div className=" py-0 mt-1">
                        1 L'élève  ne pourra passer dans la classe supérieure s'il n'a subi avec succès un examen de repechage en .... ... .. ..  . . . ..  .
                        </div>
                        <div className="row  container-fluid">
                            <div className="col-7">
                                <div className="">
                                    2 l'élève passe dans la classe superieure(1)
                                </div>
                                <div className="">
                                    3 l'élève double sa classe(1)
                                </div>
                                <div className="">
                                    2 l'élève a échoué et est à réorienter vers...
                                </div>
                            </div>
                            <div className="col-5">
                                Fait à { this.state.reportcard?.establishment?.city} le { new Date(this.state.reportcard?.updateAt).toLocaleDateString('en-GB')}
                            </div>
                        </div>
                        
                    </div>
                </div>

        </div>
        )
    }
}
export default StudentreportcardHUMANITE;